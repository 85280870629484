import { auth, provider } from "../../firebase/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import firebase from "firebase/compat/app";
import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";
import { toast } from "react-toastify";
import { FaGoogle } from "react-icons/fa";
import { addProfile } from "../../hooks/query";
import { borderDays, constellationList } from "../../utils/constans";
export const SignUp = () => {
  const navigation = useNavigate();
  const [buttonFlg, setButtonFlg] = useState(true);

  const location = useLocation();
  const { nickName, gender, birthDate } = location.state ? location.state : "";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password } = e.target.elements;

    if (
      nickName !== "" &&
      gender !== "" &&
      birthDate !== "" &&
      nickName !== undefined &&
      gender !== undefined &&
      birthDate !== undefined
    ) {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email.value,
          password.value
        );
        const date = new Date(`${birthDate}`);
        const constellation = [];
        if (!isNaN(date)) {
          const month = date.getMonth();
          const day = date.getDate();

          // 境界日付との前後関係から星座を決定
          const borderDay = borderDays[month];
          const cIndex = day < borderDay ? month : month + 1;

          if (cIndex >= constellationList.length) {
            // 12月後半生まれの場合
            constellation.push(constellationList[0]);
          } else {
            constellation.push(constellationList[cIndex]);
          }
        }

        await addProfile({
          birthDate: birthDate,
          constellation: constellation["0"],
          gender: gender,
          nickName: nickName,
          userId: user.uid,
        });

        await addDoc(collection(db, "plan"), {
          plan_name: "無料プラン",
          question_times: 1,
          adding_ticket: 0,
          userId: user.uid,
          card: "",
          customer: "",
          unlimited: false,
          planId: "",
          cancel_subscription: "",
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setButtonFlg(true);
        navigation("/home", {
          state: { message: "新規登録が完了しました" },
        });
      } catch (error) {
        toast.error("登録が失敗しました");
        console.log(error.message);
      }
    } else {
      try {
        await auth.createUserWithEmailAndPassword(email.value, password.value);

        setButtonFlg(true);
        navigation("/profile", {
          state: { message: "新規登録が完了しました" },
        });
      } catch (error) {
        toast.error("登録が失敗しました");
        console.log(error.message);
      }
    }
  };

  const handleAuth = async (e) => {
    if (
      nickName !== "" &&
      gender !== "" &&
      birthDate !== "" &&
      nickName !== undefined &&
      gender !== undefined &&
      birthDate !== undefined
    ) {
      try {
        const { user } = await auth.signInWithPopup(provider);
        const date = new Date(`${birthDate}`);
        const constellation = [];
        if (!isNaN(date)) {
          const month = date.getMonth();
          const day = date.getDate();

          // 境界日付との前後関係から星座を決定
          const borderDay = borderDays[month];
          const cIndex = day < borderDay ? month : month + 1;

          if (cIndex >= constellationList.length) {
            constellation.push(constellationList[0]); // 12月後半生まれの場合
          } else {
            constellation.push(constellationList[cIndex]);
          }
        }

        await addProfile({
          birthDate: birthDate,
          constellation: constellation["0"],
          gender: gender,
          nickName: nickName,
          userId: user.uid,
        });

        await addDoc(collection(db, "plan"), {
          plan_name: "無料プラン",
          question_times: 1,
          adding_ticket: 0,
          userId: user.uid,
          card: "",
          customer: "",
          unlimited: false,
          planId: "",
          cancel_subscription: "",
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setButtonFlg(true);
        navigation("/home", {
          state: { message: "新規登録が完了しました" },
        });
      } catch (error) {
        toast.error("登録に失敗しました");
      }
    } else {
      try {
        await auth.signInWithPopup(provider);
        navigation("/profile", {
          state: { message: "新規登録が完了しました" },
        });
      } catch (error) {
        toast.error("登録に失敗しました");
      }
    }
  };

  return (
    <>
      <HeadBlock title="新規登録" />
      <HeaderNotLogin />
      <div className="wrap not_login">
        <form onSubmit={handleSubmit}>
          <h1>今すぐ無料で新規登録</h1>
          <div className="form_wrap mt20">
            <label>メールアドレス</label>
            <input
              name="email"
              type="email"
              placeholder="メールアドレスを入力（ログインIDになります）"
            />
          </div>
          <div className="form_wrap">
            <label>パスワード</label>
            <input
              name="password"
              type="password"
              placeholder="パスワードを入力（8文字以上の英数字）"
            />
          </div>
          {buttonFlg ? (
            <button type="submit" className="btn_important">
              登録
            </button>
          ) : (
            <div className="btn_loading">
              <div className="loader"></div>
            </div>
          )}
        </form>
        <button onClick={handleAuth} className="btn_secondary mt20">
          <FaGoogle className="btn_iconfont" />
          Googleアカウントで新規登録
        </button>
        <p className="center mt30">
          ユーザーの方は
          <Link to={"/login"} className="btn_link">
            ログイン
          </Link>
        </p>
      </div>
      <Footer />
    </>
  );
};
