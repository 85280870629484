import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../../firebase/firebase";
import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";
import { toast } from "react-toastify";
export const ConfirmPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [buttonFlg, setButtonFlg] = useState(true);
  const location = useLocation();
  const oobCode = new URLSearchParams(location.search).get("oobCode");
  const navigation = useNavigate();
  const handleResetPassword = (e) => {
    e.preventDefault();
    setButtonFlg(false);
    if (password !== confirmPassword) {
      toast.error("パスワードが一致しません");
      setButtonFlg(true);
      return;
    }
    auth
      .confirmPasswordReset(oobCode, password)
      .then(() => {
        setButtonFlg(true);
        navigation("/login", {
          state: { message: "パスワード再設定が完了しました" },
        });
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/weak-password":
            toast.error(
              "パスワードが簡単すぎます。もう少し複雑なパスワードを使用してください。"
            );
            break;
          default:
            toast.error("エラーが発生しました。もう一度やり直してください。");
            break;
        }
        setButtonFlg(true);
      });
  };

  return (
    <>
      <HeadBlock title="パスワード再設定" />
      <HeaderNotLogin />
      <div className="wrap not_login">
        <h1>パスワード再設定</h1>
        <form onSubmit={handleResetPassword}>
          <div className="form_wrap mt30">
            <label>
              パスワード
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form_wrap mt30">
            <label>
              パスワード<br />（確認）
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {buttonFlg ? (
            <button type="submit" className="btn_important">登録</button>
          ) : (
            <div className="btn_loading"><div className="loader"></div></div>
          )}
        </form>
      </div>
      <Footer />
    </>
  );
};
