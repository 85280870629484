import React from "react";
import { useDateSelect } from "react-ymd-date-select";

export const BirthdateSelect = (props) => {

  const UseDateSelectOptions = {
    firstYear: 1900,
  };

  const dateSelect = useDateSelect(props.value, props.onChange, UseDateSelectOptions);

  return (
    <div className="birthdateselect_wrap">
      <div className="select">
        <select value={dateSelect.yearValue ? dateSelect.yearValue : 2000} onChange={dateSelect.onYearChange}>
          {dateSelect.yearOptions.map((yearOption) => (
            <option key={yearOption.value} value={yearOption.value}>
              {yearOption.label}
            </option>
          ))}
        </select>
      </div>
      <label>
        年
      </label>
      <div className="select">
        <select
          value={dateSelect.monthValue}
          onChange={dateSelect.onMonthChange}
        >
          <option value="" disabled></option>
          {dateSelect.monthOptions.map((monthOption) => (
            <option key={monthOption.value} value={monthOption.value}>
              {monthOption.label}
            </option>
          ))}
        </select>
      </div>
      <label>
        月
      </label>
      <div className="select">
        <select value={dateSelect.dayValue} onChange={dateSelect.onDayChange}>
          <option value="" disabled></option>
          {dateSelect.dayOptions.map((dayOption) => (
            <option key={dayOption.value} value={dayOption.value}>
              {dayOption.label}
            </option>
          ))}
        </select>
      </div>
      <label>
        日
      </label>
    </div>
  );
};
