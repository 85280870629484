import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "../../firebase/firebase";
const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigation = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== "admin@argyle.jp") {
      return;
    }
    try {
      await auth.signInWithEmailAndPassword(email, password);
      navigation("/master/home");
    } catch (error) {}
  };

  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  return (
    <div style={centerStyle}>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">ログイン</button>
      </form>
    </div>
  );
};

export default AdminLogin;
