import { HeadBlock } from "../components/HeadBlock";
import { db } from "../../firebase/firebase";
import { useState, useEffect } from "react";
import { Header } from "../components/Header";
import { useAuthContext } from "../../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { TicketForm } from "../components/TicketForm";
import { getDocs } from "firebase/firestore";
import { Footer } from "../components/Footer";
import { getProfile } from "../../hooks/query";
export const Ticket = () => {
  const { user } = useAuthContext();
  const [data, setData] = useState(null);
  const navigation = useNavigate();

  useEffect(() => {
    const profile = getProfile(user.uid);
    getDocs(profile).then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        navigation("/profile");
      }
    });
    const fetchData = async () => {
      const collectionRef = db
        .collection("plan")
        .where("userId", "==", user.uid);
      const snapshot = await collectionRef.get();
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(documents);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <HeadBlock title="追加チケット" />
        <Header />
        <div className="wrap">
          <TicketForm data={data} />
        </div>
        <Footer />
      </>
    );
  }
};
