import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";

export const Guidline = () => {
  return (
    <>
      <HeadBlock title="ガイドライン" />
      <HeaderNotLogin />
      <div className="wrap not_login">
        <div className="content_wrap">
          <h1 className="caption">ガイドライン</h1>
          <div className="content_inr">
            <p className="document">
              本ガイドラインは、アーガイル株式会社（以下、「当社」といいます）の提供する占いサービス「AI占い
              by
              チャットGPT」(以下、「本占いサービス」といいます）の会員による利用条件を定めるものであり、利用規約またはサービス利用契約と同等の法的効力を持ちます。
              <br />
              <br />
              本ガイドラインは当社の「プライバシーポリシー」、OpenAIの「利用規約」および「プライバシーポリシー」、その他これに基づく各種ガイドライン（以下、「ガイドライン等」といいます。）とともに重畳的に適用され、利用規約およびプライバシーポリシーと本ガイドラインの内容が抵触する場合には、本ガイドラインの内容が優先されるものとします。
              <br />
              また、本ガイドライン以外に詳細の説明文、注意書き等がある場合は、これらも本ガイドラインの一部として構成されます。全ての会員は、本ガイドライン、本占いサービス内に掲載される利用条件、利用規約およびプライバシーポリシーの定めに従って本占いサービスを利用しなければならず、会員はそれら全ての定めに同意しない限り、本占いサービスを利用してはなりません。お客様が未成年者である場合は、親権者等の法定代理人の同意を得たうえで本占いサービスを利用してください。
            </p>
          </div>
          <dl className="document">
            <dt>1. 用語の定義</dt>
            <dd>
              本ガイドラインにおいて使用する用語の定義は、以下のとおりとします。
              <br />
              (1) 「本占いサービス」：「AI占い by
              チャットGPT」を通じて提供される情報ならびにコンテンツ及びサービスの一切をいいます。
              <br />
              (2) 「本ガイドライン」：「AI占い by
              チャットGPTガイドライン」をいいます。
              <br />
              (3)
              「会員」：本占いサービスの利用のために必要な情報を入力し、本ガイドラインに同意した上で、登録手続き（会員登録）を終え、当社の承認を得た者をいいます。
              <br />
              (4)
              「サブスクプラン」：月額の利用料金を支払うことにより、所定のサービス・機能を利用したり、特典の付与を受けられる月額制の有料サービスをいいます。
              <br />
              (5)
              「月額サブスク会員」：月額制のサブスクプランに登録している会員をいいます。
              <br />
              (6)
              「特典等」：毎日の占い可能回数の追加など、月額サブスク会員が受けることのできる特典をいいます。
              <br />
              (7)
              「占いコンテンツ」：本占いサービスで提供する、登録情報と監修占術師の運勢アルゴリズムとChatGPTの文章生成機能を利用した占いの鑑定結果及び鑑定結果を示した文章、占いに関する画像等の生成コンテンツをいいます。
              <br />
              (8)
              「登録情報」：会員登録の際に登録する情報のうち、本占いサービスの利用上必要な情報（ニックネーム、生年月日、性別など）をいいます。なお、登録情報についてはユーザーが自らSNSへの占い結果のシェアなどをしない限り、公開されません。
              <br />
              (9)
              「個人情報」：会員により登録・送信された情報のうち、特定の個人を識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することが可能となるものを含む）をいいます。
              <br />
              (10)
              「占いチケット」：本占いサービスでご利用いただけるサービス内のチケットのことをいい、会員による購入、または当社が無償で付与するものがあります。なお、一度購入したチケットは、返品または現金に交換することはできません。また、当社が発行する他の前払式支払手段やポイント等との合算または交換もできません。
              <br />
              (11)
              「占える回数」：本占いサービスのサブスク会員に対して毎日自動的に付与される、占いコンテンツを利用可能な権利のことをいいます。
              <br />
              (12)
              「監修占術師」：本占いサービスで提供されるコンテンツを生成する際の運勢アルゴリズム、占いデータ等の作成ならびに監修を行った占い師のことをいいます。
              <br />
              (13)
              「AI」：占術師の作成ならびに監修した運勢アルゴリズムや占いデータ等を元に、本占いサービスで提供される占いコンテンツの生成を支援する人工知能プログラムのことをいいます。本占いサービスではOpenAIの「ChatGPT」を主に使用しています。
            </dd>
            <dt>2. サービスの概要</dt>
            <dd>
              1.
              本占いサービスは、会員の登録した情報と監修占術師の作成および監修した運勢アルゴリズムならびに占いデータに基づいてAIにより生成された文章、画像等のコンテンツを用いて、様々な占いサービスを提供いたします。
              <br />
              2.
              会員は、初回のみ無料で本占いサービスを利用することができますが、明示される利用料を支払うことにより、追加の占い回数および本占いサービス内で提供している有料コンテンツを利用することができます。
              <br />
              3.
              当社は、必要に応じて、本占いサービスに新しいサービスを追加したり、変更したりすることがあり、利用者はそれをあらかじめ承諾した上で本占いサービスを利用するものとします。
              <br />
              4.
              利用者は、年齢や利用環境等、当社の定める条件に応じて、当社の定める範囲内で、本占いサービスを利用するものとします。
              <br />
              5. 本占いサービスは、OpenAI（営利法人OpenAI
              LPならびにその親会社である非営利法人OpenAI Inc.
              ）が商用に提供するChatGPTのAPIを利用規約に基づいて連携して運用されておりますが、ChatGPTの公式サービスやOpenAIが自ら運営するサービスではありません。
              <br />
              6.本占いサービスで提供されるコンテンツは、監修占術師が作成および監修を行った運勢アルゴリズムならびに占いデータを元に生成しておりますが、ChatGPTによる文章生成処理を経たものであり、監修占術師本人の意見、思想、鑑定結果等と必ずしも一致するものではありません。
            </dd>
            <dt>3. サービスの利用設定</dt>
            <dd>
              (1)
              本占いサービスの会員登録を希望する方は、当社が定める情報を入力し、その後当社の定める認証手続きを行う必要があります。なお、会員登録時に提供された情報に虚偽があった場合は、会員登録が無効となります。
            </dd>
            <dt>4. 登録情報および個人情報</dt>
            <dd>
              (1)
              当社は、本占いサービスにおいて利用者の登録情報および個人情報を、占いコンテンツの提供、サービスの改善のためのマーケティング分析、当社からのサービスに関するメール送信の目的で利用します。なお、登録情報、個人情報の利用は会員の閲覧履歴・購買履歴等、または会員が登録した名前・年齢・生年月日・性別等の情報を分析し、占いサービスの提供ならびに改善に利用することを含みます。登録情報および個人情報を第三者に販売・譲渡することはありません。
              <br />
              登録情報および個人情報の取り扱いに関しては、アーガイル株式会社のプライバシーポリシーが適用されます。プロフィール登録情報ならびにテキスト入力情報の取扱については、ChatGPTの利用規約ならびにプライバシーポリシーも同様に適用されます。
              <br />　
              <a
                href="https://argyle.jp/privacy.html"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                アーガイル株式会社プライバシーポリシー
              </a>
              <br />　
              <a
                href="https://openai.com/policies/terms-of-use"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                OpenAI利用規約
              </a>
              <br />　
              <a
                href="https://openai.com/policies/privacy-policy"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                OpenAIプライバシーポリシー
              </a>
              <br />
              <br />
              なお、当社の本占いサービスにおける、本件お間合せ窓口は以下のとおりです。
              <br />
              <a
                href="https://forms.gle/kvW4Mm9TwSfdcEJi7"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                アーガイル株式会社「AI占い by チャットGPT」カスタマーサポート
              </a>
            </dd>
            <dt>5. 有料サービス</dt>
            <dd>
              有料サービスの登録は、利用者による当社所定の申込手続きに対し、当社が当該申込みを承諾した時点で当社と利用者間で有料サービスの利用に関する契約が成立し、これにより有料サービスへの登録が完了するものとします。有料サービスには次の種類がありますが、個々のサービス内容の詳細条件は本占いサービス内に表記されます。
              <br />
              <br />
              1.
              「サブスクプラン」：　月額定額料金を支払うことで毎日一定回数の「占える回数」を行使できますが、回数は翌日以降に持ち越すことはできません。「占える回数」または「占いチケット」を消費することで占いサービスの利用またはコンテンツの購入ができます。
              <br />
              2.
              「プレミアムサブスクプラン」：　月額定額料金を支払うことで、上記のサブスクプランに加え、プレミアム会員限定のコンテンツや特典をうけることができます。
              <br />
              3.
              「占いチケット（10回分、30回分、等）」：　指定の料金を支払うことで、占いサービスを利用可能なチケットの枚数を増やせます。
              <br />
              4.
              「1日無制限チケット」：　指定の料金を支払うことで、最大1日間、利用回数に上限なく占いサービスを利用することができます。無制限利用の権利は、翌午前8時に自動的に解除されます。
            </dd>
            <dt>6. 「占える回数」と「占いチケット」について</dt>
            <dd>(1) 「占える回数」は次の場合に会員に対して付与されます。<br />
              ①会員登録時に、初回のみ無料で１回分を付与<br />
              ② サブスク会員に対して、毎朝8時に6回分を付与<br />
              ③キャンペーン等、別途当社が定める方法<br />
              (2) 「占える回数」は消費と引き換えに占いサービスを利用する権利であり、有効期限は最大１日間（毎日午前８時に自動的に前日分の回数が消失し、また当日分の回数が新たに追加されます）です。<br />
              (3) 「占いチケット」は次の場合に会員に対して付与されます。<br />
              ①サブスク会員による占いチケットの購入時<br />
              ②キャンペーン等、別途当社が定める方法<br />
              (4) 占いチケットは、消費と引き換えに占いサービスを利用する権利であり、有効期限は、チケットの付与日の属する月の6ヶ月後の末日までとなります。（例えば、3月20日に付与された占いチケットは、9月末日までご利用いただけます。）<br />
              (5) 会員は管理画面から「占える回数」と「占いチケット」の現在の所持数を確認することができます。<br />
              (6) 「占える回数」が残っている場合は、「占いチケット」を消費せず、「占える回数」から優先的に消費されるものとします。
            </dd>
            <dt>7. 解約について</dt>
            <dd>1. 解約には、サブスクプランの解約、本占いサービスの利用停止、会員データの削除、の3通りがあります。<br />
              2. 月額プレミアムを解約する場合には、マイページ→「プランの変更」から、サブスク契約の更新停止手続きを行うことができます。月額サブスク会員は、ご自身で更新予定日の３０日前までにお手続きください。自動更新処理後に解約した場合は、翌々月からの停止となります。<br />
              3. 本占いサービスの利用を停止する場合は、ヘルプページの「お問い合わせ」より、当社宛てに解約希望の通知を行ってください。<br />
              4. 自身の会員データの削除を行いたい場合は、「お問い合わせ」窓口より、当社宛てに「会員データ削除希望」の通知を行ってください。その際に本人確認書類の提出を依頼することがあります。
            </dd>
            <dt>8. 本占いサービスの内容および占いコンテンツの利用権</dt>
            <dd>
              (1)
              当社は、会員に対し、本規約、本占いサービス内に掲載される利用条件に従って本占いサービスおよび占いコンテンツを利用する権利を許諾します。お客様は、本占いサービスおよび占いコンテンツの利用権を第三者に譲渡、貸与、再許諾その他の処分または相続させたりすることはできず、本占いサービス内での利用を唯一の目的とする本占いサービスおよび占いコンテンツの利用権のみが付与されます。
              <br />
              (2)
              会員は、本占いサービスの利用に際して、以下の事項またはそれに関連する内容の相談または投稿・発言を行ってはなりません。当社は、会員の相談または投稿・発言内容が以下の事項に該当する、または該当するおそれがあると合理的に判断した場合、その他業務上の合理的な必要がある場合、あらかじめ会員に通知することなく、当該相談に関する本占いサービスの提供を中止する等の方法により、本占いサービスの利用を制限することができます。
              <br />
              ①法律・税務等の相談、病気の診断、医薬品の処方、またはペットの診察等の法令上有資格者に相談することが義務付けられていること
              <br />
              ② 具体的な就職先・転職先の紹介または斡旋の依頼
              <br />
              ③ 人の生死、宗教、健康法、民間療法に関わること
              <br />
              ④ ギャンブル、株、投資の予想
              <br />
              ⑤ 試験の合否
              <br />
              ⑥ 著名人・芸能人に関わること
              <br />
              ⑦ 探偵業に類する人探し、身辺調査、浮気調査等の相談
              <br />
              ⑧ 紛失物等の問い合わせ
              <br />
              ⑨
              犯罪その他の法令違反行為、権利侵害行為、公序良俗に反する行為に関わること
              <br />
              ⑩ 同業者の勧誘または情報収集と判断されること
              <br />
              ⑪
              占いコンテンツの提供者（本占いサービスの運営事業者、アドバイスを提供する者、占い師、カウンセラー、AI等を含みます。）に関する個人的な質問または嫌がらせや誹謗中傷誹謗等、占いコンテンツの提供者に対する不当な問い合わせまたは要求
              <br />
              ⑫ 過度の性的または暴力的な発言、内容であること
              <br />
              ⑬
              相談とはかけ離れた内容である等、相談すること以外が主目的であると判断されること
              <br />
              ⑭
              リバースエンジニアリング、プログラムコードやプロンプト等の構造や設計仕様を探る目的での電磁操作、ハッキング等の行為。
              <br />
              ⑮
              その他、当社が本占いサービスの対象として適切ではないと判断すること
              <br />
              (3)
              本占いサービスは娯楽を目的としたものであり、当社は、占いコンテンツについて、占いコンテンツの効果、信頼性、正確性、完全性、有効性、特定の目的への適合性、実現性等を保証するものではありません。
              <br />
              (4)
              当社は、本占いサービスが、法律に基づく国家資格その他の資格に基づいて提供されることを保証するものではありません。
              <br />
              (5)
              当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本占いサービスの全部または一部の内容を変更することができます。
              <br />
              (6)
              当社は、占いコンテンツの利用履歴情報を会員の利便性のために保存することがあります。利用履歴情報の一部は、会員ログイン後の管理画面で確認できます。利用履歴情報は会員本人がSNSへのシェア、転載、保存等の行為をしない限り、他の会員や第三者には公開されません。また、一定期間または一定件数を超えた利用履歴情報は、事前の断りなく削除される場合があります。
              <br />
              (7)
              当社は、以下のいずれかに該当する場合、あらかじめお客様に通知することなく、本占いサービスの全部または一部の提供を中止することができます。
              <br />
              ① システムのメンテナンスまたは修理の実施を行う場合
              <br />
              ②
              火災・停電等の事故、天災、戦争、暴動、労働争議等の不可抗力により、本占いサービスの提供ができなくなった場合
              <br />
              ③
              システムおよびAPIの障害が発生した場合またはシステムおよびAPIに負荷が集中した場合
              <br />
              ④
              お客様または第三者の安全を確保する場合または公共の利益のために緊急を要する場合
              <br />⑤
              ①から④までに定めるもののほか、当社が必要と合理的に判断した場合
            </dd>
            <dt>9. 利用契約の終了または無料プランへの移行後の措置</dt>
            <dd>
              (1)
              当社は、利用契約が終了した後または本占いサービスの無料プランへの移行後も、登録事項、本占いサービスの利用に関するデータ等、ご利用者に関する一切の情報を保有できるものとします。無料プランへの移行後も、所持している占いチケットは、有効期限まで利用できるものとします。
              <br />
              (2)
              利用契約の終了後または本占いサービスの無料プランへの移行後に、ご利用者から当社に対し、ご利用者に関するデータの削除の申し入れがあった場合には、当社は、合理的な期間内に、当社が管理する当該ご利用者に関するデータを削除するものとします。利用者に関するデータの削除後は、占い履歴、占える回数、占いチケット、サブスクプラン、無制限チケットなどの利用権は全て無効となります。
            </dd>
            <dt>10. 免責事項</dt>
            <dd>
              (1)
              会員は、本占いサービスの利用に際して、会員自身の責任において、相談、投稿ならびにコンテンツの利用を行うものとし、当該相談または投稿行為およびその結果、アドバイス内容、アドバイスに基づく行動について会員自身が一切の責任を負うものとします。
              <br />
              (2)
              本占いサービスは、生成されるコンテンツの精度、完成度、最新性、正確性、妥当性、道徳性、安全性、適用性、適法性、有用性、可用性、第三者への権利侵害等について保証いたしません。会員は、自己の責任と判断において本占いサービスを利用するものとします。
              <br />
              (3)
              会員が、本占いサービスに掲載されている情報及びこれに係るコンテンツを利用する場合は、利用目的に応じて会員自身の判断と責任において行うものとします。
              <br />
              (4)
              当社は、本占いサービスにおいて、会員相互間及び会員と第三者との間で生じたトラブル（違法または公序良俗に反する行為の提案、名誉毀損、侮辱、プライバシー侵害、脅迫、誹謗中傷、いやがらせ等）に関して、当社に故意または重過失がある場合を除き、責任を負わないものとします。
              <br />
              (5)
              当社は、本占いサービスにおいて提供されるコンテンツの内容が、利用者もしくは第三者の権利を侵害し、または権利の侵害に起因して紛争が生じた場合であっても、当社に故意または重過失がある場合を除き、その侵害及び紛争に対して責任を負わないものとします。
            </dd>
            <dt>11. 反社会的勢力の排除</dt>
            <dd>
              (1)
              会員は、利用契約の締結及び履行に関して、当社に対して、次の各号に規定する事項について表明し保証します。
              <br />
              ①会員は、暴力団、暴力団員、過去に暴力団員であった者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団その他これらに準ずる者（以下、総称して「反社会的勢力」といいます。）ではなく、将来においてもこれらに該当しないこと
              <br />
              ②会員が法人の場合、その役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいいます。以下同じです。）が反社会的勢力ではなく、将来においてもこれらに該当しないこと
              <br />
              ③会員は、反社会的勢力に自己の名義を利用させ、この契約を締結するものでないこと
              <br />
              (2)
              会員は、利用契約の締結及び履行に関して、自ら、自らの役員又は第三者を利用して、次の各号の一つに該当する行為をしてはいけません。
              <br />
              ①当社に対する脅迫的な言動又は暴力を用いる行為
              <br />
              ②偽計又は威力を用いて当社の業務を妨害し、又は信用を毀損する行為
              <br />
              ③当社に対して法的責任を超えた不当な要求をすること
              <br />
              ④反社会的勢力への資金提供その他その活動を助長する行為
              <br />
              ⑤反社会的勢力と社会的に非難されるべき関係を有すること
              <br />
              (3)
              ご利用者について、本条第1項及び第2項に反する事実又は行為が判明した場合、当社は、何らの催告も要せずして、利用契約を解除することができます。
              <br />
              (4)
              前項の規定により利用契約が解除された場合、ご利用者は、当社に対し、解除により生じる損害について、一切の請求を行わないものとし、また、ご利用者は、利用契約の解除にかかる費用を負担するものとします。
            </dd>
            <dt>12. 本規約の変更</dt>
            <dd>
              (1)
              当社は、本規約の内容を変更すること（本規約に新たな内容を追加することを含みます。）ができるものとします。
              <br />
              (2)
              当社は、前項の変更を行う場合、相当な予告期間をおいて、本規約を変更する旨、変更後の本規約の効力発生日及び変更後の新たな利用規約の内容を、当社のウェブサイト上への掲載その他の適切な方法により、ご利用者に周知するものとします。
            </dd>
            <dt>13. 管轄裁判所</dt>
            <dd>
              ご利用者と当社との間で、本占いサービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所において、紛争の解決を図るものとします。
            </dd>
            <dt>14. 存続条項</dt>
            <dd>
              利用契約がいかなる理由により終了した場合であっても、2.
              （サービスの概要）、4.（登録情報および個人情報）、7.（解約について）、8.（本占いサービスの内容および占いコンテンツの利用権）、9.（利用契約の終了または無料プランへの移行後の措置）、10.（免責事項）、11.（反社会的勢力の排除）、13.（管轄裁判所）、15.（準拠法）から16.（協議事項）の規定は有効に存続するものとします。
            </dd>
            <dt>15. 準拠法</dt>
            <dd>利用契約に関する準拠法は、日本法とします。</dd>
            <dt>16. 協議事項</dt>
            <dd>
              本規約についてあるいは本規約に定めのない事項について紛議が生じた場合、ご利用者と当社は共に誠意を持って問題の解決に当たることとします。
            </dd>
          </dl>
          <p className="document mt30">2023年4月1日　制定</p>
        </div>
      </div>
      <Footer />
    </>
  );
};
