import { NavLink } from "react-router-dom";
import { FaFacebookSquare, FaLine } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiHatenabookmark } from "react-icons/si";

export const Footer = () => {
  return (
    <>
      <div className="foot_wrap"></div>
      <footer>
        <div className="sns_share_wrap">
          <div className="caption">シェアする</div>
          <a
            href="//twitter.com/share?url=https%3A%2F%2Fai-uranai.jp&text=AI%E5%8D%A0%E3%81%84%20by%20%E3%83%81%E3%83%A3%E3%83%83%E3%83%88GPT%20-%20%E6%97%A5%E6%9C%AC%E5%88%9D%E3%81%AE%E6%9C%AC%E6%A0%BCAI%E5%8D%A0%E3%81%84%EF%BC%88%E5%8D%A0%E8%A1%93%E5%B8%ABSAIKI%20%C3%97%20ChatGPT%EF%BC%89"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSquareXTwitter />
          </a>
          <a
            href="//www.facebook.com/sharer/sharer.php?u=https://ai-uranai.jp&t=AI%E5%8D%A0%E3%81%84%20by%20%E3%83%81%E3%83%A3%E3%83%83%E3%83%88GPT%20-%20%E6%97%A5%E6%9C%AC%E5%88%9D%E3%81%AE%E6%9C%AC%E6%A0%BCAI%E5%8D%A0%E3%81%84%EF%BC%88%E5%8D%A0%E8%A1%93%E5%B8%ABSAIKI%20%C3%97%20ChatGPT%EF%BC%89"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="//timeline.line.me/social-plugin/share?url=https%3A%2F%2Fai-uranai.jp&text=AI%E5%8D%A0%E3%81%84%20by%20%E3%83%81%E3%83%A3%E3%83%83%E3%83%88GPT%20-%20%E6%97%A5%E6%9C%AC%E5%88%9D%E3%81%AE%E6%9C%AC%E6%A0%BCAI%E5%8D%A0%E3%81%84%EF%BC%88%E5%8D%A0%E8%A1%93%E5%B8%ABSAIKI%20%C3%97%20ChatGPT%EF%BC%89"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaLine />
          </a>
          <a
            href="//b.hatena.ne.jp/add?mode=confirm&url=https%3A%2F%2Fai-uranai.jp&title=AI%E5%8D%A0%E3%81%84%20by%20%E3%83%81%E3%83%A3%E3%83%83%E3%83%88GPT%20-%20%E6%97%A5%E6%9C%AC%E5%88%9D%E3%81%AE%E6%9C%AC%E6%A0%BCAI%E5%8D%A0%E3%81%84%EF%BC%88%E5%8D%A0%E8%A1%93%E5%B8%ABSAIKI%20%C3%97%20ChatGPT%EF%BC%89"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <SiHatenabookmark />
          </a>
        </div>
        <nav>
          <NavLink to={"/guidline"}>ガイドライン</NavLink> ｜
          <NavLink to={"/specified"}>特定商取引法</NavLink>
        </nav>
        <nav>
          <NavLink to="https://forms.gle/kvW4Mm9TwSfdcEJi7" target="_blank">
            お問い合わせ
          </NavLink>{" "}
          ｜
          <NavLink to="https://chatgpt-api-developper.argyle.jp/" target="_blank">
            開発運営会社
          </NavLink>
        </nav>
        <nav>
          <NavLink to="https://ai-uranai.jp" className="blue">
            ai-uranai.jp
          </NavLink>
        </nav>
        <p>
          <NavLink to="https://argyle.jp/" className="blue" target="_blank">
            Copyright&copy;アーガイル株式会社
          </NavLink>
        </p>
      </footer>
    </>
  );
};
