import { useState } from "react";
import { resetPassword } from "../../hooks/query";
import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";
import { toast } from "react-toastify";

export const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [buttonFlg, setButtonFlg] = useState(true);
  const [sendedFlg, setSendedFlg] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !email.match(/\S/g)) {
      toast.error("メールアドレスを入力してください");
      return;
    }

    setButtonFlg(false);

    try {
      resetPassword(email);
      toast.success("パスワード再設定メールを送信しました。");
      setButtonFlg(true);
      setSendedFlg(true);
    } catch (error) {
      toast.error("エラーが発生しました。もう一度やり直してください。");
      setButtonFlg(true);
    }
  };

  return (
    <>
      <HeadBlock title="パスワード再設定" />
      <HeaderNotLogin />
      <div className="wrap not_login">
        <h1>パスワード再設定</h1>
        {sendedFlg ? (
          <p className="center mt50">
            パスワードリセット用のリンクを送信しました。<br />
            メールを確認して、リンクをクリックしてください。
          </p>
        ) : (
          <>
            <p className="center mt30">
              ユーザー登録時のメールアドレスを入力してください。
              <br />
              パスワードを再設定するリンクをお送りいたします。
            </p>
            <form onSubmit={handleSubmit}>
              <div className="form_wrap mt30">
                <label>メールアドレス</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {buttonFlg ? (
                <button type="submit" className="btn_important">
                  送信
                </button>
              ) : (
                <div className="btn_loading"><div className="loader"></div></div>
              )}
            </form>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};
