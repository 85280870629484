import { db } from "../../firebase/firebase";
import { useNavigate, Navigate, Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useEffect, useState, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import { v4 as uuidv4 } from "uuid";
import { Header } from "../components/Header";
import { getDocs } from "firebase/firestore";
import dayjs from "dayjs";
import { HeadBlock } from "../components/HeadBlock";
import { Footer } from "../components/Footer";
import { getProfile } from "../../hooks/query";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { formatDate } from "../../utils/functions";
import TextOverlay from "../components/TextOverlay";
export const Mypage = () => {
  const navigation = useNavigate();
  const { user } = useAuthContext();
  const [profile, setProfile] = useState(null);
  const [plan, setPlan] = useState(null);
  const [fortune, setFortune] = useState("恋愛運");
  const [fortunes, setFortunes] = useState([]);
  const [buttonFlg, setButtonFlg] = useState(true);

  const location = useLocation();
  const { state } = location;
  const toastId = useRef(null);
  useEffect(() => {
    const profileData = getProfile(user.uid);
    getDocs(profileData).then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        navigation("/profile");
      }
    });

    const fetchProfile = async () => {
      const Ref = db.collection("profiles").where("userId", "==", user.uid);
      const snap = await Ref.get();
      const doc = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProfile(doc);
    };
    const fetchPlan = async () => {
      const Ref = db.collection("plan").where("userId", "==", user.uid);
      const snap = await Ref.get();
      const doc = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPlan(doc["0"]);
    };

    const fortunesRef = db.collection("fortunes");

    fortunesRef
      .where("category", "==", fortune)
      .where("userId", "==", user.uid)
      .where("reply_detail", "!=", null)
      .orderBy("reply_detail")
      .orderBy("created_at", "desc")
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
            createdAt: dayjs(doc.data().created_at.toDate()).format(
              "YYYY年MM月DD日 H時mm分"
            ),
          });
        });
        // データをcreatedAtの降順にソート
        data.sort((a, b) => {
          return b.created_at.toDate() - a.created_at.toDate();
        });
        setFortunes(data);
        console.log(fortunes);
        setButtonFlg(true);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    fetchProfile();
    fetchPlan();

    if (toastId.current) {
      toast.update(toastId.current, { autoClose: 3000 });
    } else if (state) {
      toastId.current = toast.success(state.message, {
        autoClose: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fortune]);

  const handleOptionChange = (e) => {
    setButtonFlg(false);
    setFortune(e.target.value);
  };

  // ここからHTML
  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <HeadBlock title="マイページ" />
        <Header />
        <div className="wrap">
          {plan || profile ? (
            <>
              {plan && (
                <div className="content_wrap">
                  <p className="caption">現在のプラン</p>
                  {plan.plan_name === "サブスクプラン" && (
                    <Link to={"/checkout"} className="btn_link">
                      プランの変更
                    </Link>
                  )}
                  <div className="content_inr">
                    {plan.plan_name === "サブスクプラン" && (
                      <p>サブスクプラン（毎日6回、月額500円）</p>
                    )}
                    {plan.plan_status === "解約予定" && (
                      <p>有効期限：{plan.plan_end}まで</p>
                    )}
                    {plan.unlimited && (
                      <p>
                        1日無制限チケット（有効期限：{plan.cancel_subscription}{" "}
                        8時まで）
                      </p>
                    )}
                  </div>
                  {plan.plan_name === "無料プラン" && !plan.unlimited && (
                    <Link to={"/checkout"} className="btn_gradient non_spacing">
                      サブスクプランに加入して、{isMobile && <br />}
                      あなたの毎日を占いましょう
                    </Link>
                  )}
                </div>
              )}
              <div className="content_wrap">
                <p className="caption">あなたのプロフィール</p>
                <Link to={"/profile/edit"} className="btn_link">
                  プロフィール編集
                </Link>
                {profile && (
                  <div className="content_inr">
                    <dl>
                      <dt>ニックネーム</dt>
                      <dd>{profile[0].nickName}</dd>
                      <dt>生年月日</dt>
                      <dd>{formatDate(profile[0].birthDate)}</dd>
                      <dt>性別</dt>
                      <dd>{profile[0].gender}</dd>
                      <dt>ユーザー情報</dt>
                      <dd>{profile[0].introduction}</dd>
                    </dl>
                  </div>
                )}
              </div>
              <div className="content_wrap">
                <p className="caption">今までの占い結果</p>
                <div className="content_inr radio_wrap">
                  <input
                    type="radio"
                    id="category_love"
                    value="恋愛運"
                    checked={fortune === "恋愛運"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="category_love"
                    className="radio_label category_love"
                  >
                    恋愛
                  </label>
                  <input
                    type="radio"
                    id="category_work"
                    value="仕事運"
                    checked={fortune === "仕事運"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="category_work"
                    className="radio_label category_work"
                  >
                    仕事
                  </label>
                  <input
                    type="radio"
                    id="category_money"
                    value="金運"
                    checked={fortune === "金運"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="category_money"
                    className="radio_label category_money"
                  >
                    お金
                  </label>
                  <input
                    type="radio"
                    id="category_other"
                    value="全体運"
                    checked={fortune === "全体運"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="category_other"
                    className="radio_label category_other"
                  >
                    その他
                  </label>
                </div>
                {buttonFlg ? (
                  <>
                    {fortunes.map((fortune) => (
                      <div key={fortune.id} className="answer_wrap">
                        <p>{fortune.question_content}</p>
                        <div className="answer">
                          {fortune.reply_detail
                            .split("\n")
                            .map((detail, idx) => (
                              <span key={idx}>
                                {detail}
                                <br />
                              </span>
                            ))}
                        </div>
                        <div className="info_wrap">
                          <div className="btn_wrap">
                            <TextOverlay
                              url="https://ai-uranai.jp"
                              text={fortune.question_content}
                            />
                          </div>
                          <p className="date">{fortune.createdAt}</p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="loading">
                    <div className="loader"></div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="loading">
              <div className="loader"></div>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
};
