export function formatDate(dateString) {
  // 文字列からDateオブジェクトを作成
  const date = new Date(dateString);

  // フォーマットを変更する方法を選択（例：YYYY年MM月DD日）
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}年${month}月${day}日`;
}

export function sanitizeInput(input) {
  return input.replace(/<script.*?>.*?<\/script>/gi, "");
}

export function getToday() {
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);

  return `${year}年${month}月${day}日`;
}
export function unlimitedDay() {
  const now = new Date();
  const hour = now.getHours();

  let date = now.getDate();
  let month = now.getMonth() + 1;
  let year = now.getFullYear();

  if (hour < 8) {
    date = now.getDate();
    month = now.getMonth() + 1;
    year = now.getFullYear();
  } else {
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    date = tomorrow.getDate();
    month = tomorrow.getMonth() + 1;
    year = tomorrow.getFullYear();
  }

  return `${year}年${month}月${date}日`;
}

// Topページで使用。YYYY年mm月dd日
export function getDay() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const formattedMonth = String(month);
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}年${formattedMonth}月${day}日`;
}
