import { useEffect, useRef, useState } from "react";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { unlimitedDay } from "../../utils/functions";
import parse from "html-react-parser";

export const TicketForm = (props) => {
  const plan = props;
  const [buttonFlg, setButtonFlg] = useState(true);
  const demoElement = useRef(null);
  const navigation = useNavigate();
  const { user } = useAuthContext();
  const [payInfo, setPayInfo] = useState(null);

  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedName, setSelectName] = useState(null);
  const [selectedPrice, setSelectPrice] = useState("");
  const [selectedPlan, setSelectPlan] = useState(null);

  const [showPaymentInputs, setShowPaymentInputs] = useState(false);

  useEffect(() => {
    const fetchPayInfo = async () => {
      try {
        const q = query(
          collection(db, "profiles"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
          // データが存在しない場合の処理
          navigation("/profile");
          return;
        }

        const planQuerySnapshot = await firebase
          .firestore()
          .collection("plan")
          .where("userId", "==", user.uid)
          .get();

        const newData = await planQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          cvc: doc.get("cvc"),
          expMonth: doc.get("expMonth"),
          number: doc.get("number"),
          expYear: doc.get("expYear"),
          adding_ticket: doc.get("adding_ticket"),
          unlimited: doc.get("unlimited"),
          question_times: doc.get("question_times"),
          customer: doc.get("customer"),
          cancel_subscription: doc.get("cancel_subscription"),
        }));
        setPayInfo(newData);
      } catch (error) {
        console.error(error);
      }
    };
    const loadScriptAndFetchData = async () => {
      await fetchPayInfo();

      const script = document.createElement("script");
      script.src = "https://js.pay.jp/v2/pay.js";
      script.async = true;
      // if (showPaymentInputs) {
      //   await createPayForm();
      // } else {
      script.onload = async () => {
        await createPayForm();
      };
      // }

      document.body.appendChild(script);
    };

    loadScriptAndFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPaymentInputs]);

  const createPayForm = async () => {
    if (window.Payjp && demoElement.current) {
      const payjp = window.Payjp("pk_live_ea9f610862a9060461dbf41f");

      const elements = payjp.elements();
      const cardElement = elements.create("card");

      cardElement.mount("#v2-demo");

      demoElement.current.addEventListener("submit", async (e) => {
        e.preventDefault();

        setButtonFlg(false);

        const token = await payjp.createToken(cardElement);

        if (showPaymentInputs) {
          try {
            const subscription = firebase
              .functions()
              .httpsCallable("cardChange");
            const result = await subscription({
              token: token.id,
              email: user.email,
              uid: user.uid,
              customer: payInfo[0].customer,
            });
            if (result.data == null) {
              setButtonFlg(true);
            } else {
              toast.success("カードの変更が完了しました。");
              window.location.reload();
            }
          } catch (error) {
            setButtonFlg(true);
            toast.error("変更に失敗しました。もう一度入力をお願いします");
          }
        } else {
          try {
            const postPayjp = firebase.functions().httpsCallable("CardToken");
            const result = await postPayjp({
              token: token.id,
              email: user.email,
              uid: user.uid,
            });
            if (result.data == null) {
              toast.error("登録に失敗しました。もう一度入力をお願いします");
              setButtonFlg(true);
            } else {
              toast.success("カードの登録が完了しました。");
              window.location.reload();
            }
          } catch (error) {
            setButtonFlg(true);
            toast.error("登録に失敗しました。もう一度入力をお願いします");
          }
        }
      });
    }
  };

  const openModal = async (e) => {
    e.preventDefault();
    setSelectName(e.currentTarget.dataset["name"]);
    setSelectPrice(e.currentTarget.dataset["price"]);
    setSelectPlan(e.currentTarget.dataset["plan"]);
    setIsOpen(true);
  };

  const closeModal = async (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  // 購入処理
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonFlg(false);

    // サブスクあり or サブスクなし＆無制限チケット購入
    if (
      plan.data["0"]["plan_name"] === "サブスクプラン" ||
      (plan.data["0"]["plan_name"] === "無料プラン" &&
        selectedPlan === "無制限チケット")
    ) {
      try {
        const cancel_sub = unlimitedDay();

        const subscription = firebase.functions().httpsCallable("AllTicket");

        const result = await subscription({
          plan: selectedPlan,
          uid: user.uid,
          cancel_sub: cancel_sub,
          status: payInfo[0].unlimited,
          adding_ticket: payInfo[0].adding_ticket,
          customer: payInfo[0].customer,
        });
        if (result.data == null) {
          toast.error("決済に失敗しました。もう一度入力をお願いします");
          setButtonFlg(true);
          setIsOpen(false);
        } else {
          setButtonFlg(true);
          setIsOpen(false);
          navigation("/home", {
            state: { message: "購入が完了しました" },
          });
        }
      } catch (error) {
        console.log(error);
        setButtonFlg(true);
        setIsOpen(false);
        toast.error("決済に失敗しました。もう一度入力をお願いします");
      }

      // サブスクなし＆サブスクプラン購入
    } else if (
      plan.data["0"]["plan_name"] === "無料プラン" &&
      selectedPlan === "サブスクプラン"
    ) {
      try {
        const cancel_sub = unlimitedDay();

        const postUnlimited = firebase
          .functions()
          .httpsCallable("UnlimitedTicket");

        const result = await postUnlimited({
          uid: user.uid,
          cancel_sub: cancel_sub,
          status: selectedName,
          ticket: payInfo[0].adding_ticket,
          customer: payInfo[0].customer,
        });
        if (result.data == null) {
          toast.error("決済に失敗しました。もう一度入力をお願いします");
          setButtonFlg(true);
          setIsOpen(false);
        } else {
          setButtonFlg(true);
          setIsOpen(false);
          navigation("/home", {
            state: { message: "購入が完了しました" },
          });
        }
      } catch (error) {
        console.log(error);
        setButtonFlg(true);
        setIsOpen(false);
        toast.error("決済に失敗しました。もう一度入力をお願いします");
      }
    }
  };

  const PaymentSection = () => {
    if (plan.data !== null && payInfo !== null) {
      return (
        <>
          {plan.data["0"]["card"] ? (
            <>
              {showPaymentInputs ? (
                <div className="payment_wrap">
                  <form ref={demoElement}>
                    <div id="v2-demo" className="payment_form"></div>
                    <p className="note">
                    ＊CVC番号は、クレジットカード番号とは別に印字されている3桁または4桁の数字です。
                    </p>
                    <img src="/images/img_cvc.png" className="credit" alt="＊CVC番号は、クレジットカード番号とは別に印字されている3桁または4桁の数字です。" />
                    {buttonFlg ? (
                      <div className="btn_wrap">
                        <button type="submit" className="btn_important">
                          登録
                        </button>
                        <button
                          onClick={() =>
                            setShowPaymentInputs((prevState) => !prevState)
                          }
                          className="btn_secondary"
                        >
                          キャンセル
                        </button>
                      </div>
                    ) : (
                      <div className="btn_wrap">
                        <div className="btn_loading">
                          <div className="loader"></div>
                        </div>
                      </div>
                    )}
                  </form>
                  <img src="/images/img_credit.png" className="credit" alt="" />
                  <img src="/images/logo_payjp.png" alt="" />
                </div>
              ) : (
                <div className="content_wrap">
                  <p className="big">登録済みのカードで支払い</p>
                  {/* <button onClick={handleClick}>Pay with PayPay</button> */}
                  <button
                    className="btn_link"
                    onClick={() =>
                      setShowPaymentInputs((prevState) => !prevState)
                    }
                  >
                    変更
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <p className="center">
                クレジット情報を「登録」してから、ご希望のプランの「購入」ボタンを押してください。
              </p>
              <div className="payment_wrap">
                <form ref={demoElement}>
                  <div id="v2-demo" className="payment_form"></div>
                  <p className="note">
                  ＊CVC番号は、クレジットカード番号とは別に印字されている3桁または4桁の数字です。
                  </p>
                  <img src="/images/img_cvc.png" className="credit" alt="＊CVC番号は、クレジットカード番号とは別に印字されている3桁または4桁の数字です。" />
                  {buttonFlg ? (
                    <div className="btn_wrap">
                      <button type="submit" className="btn_important">
                        登録
                      </button>
                    </div>
                  ) : (
                    <div className="btn_wrap">
                      <div className="btn_loading">
                        <div className="loader"></div>
                      </div>
                    </div>
                  )}
                </form>
                <img src="/images/img_credit.png" className="credit" alt="" />
                <img src="/images/logo_payjp.png" alt="" />
              </div>
            </>
          )}
        </>
      );
    }
  };

  const TicketSection = () => {
    if (plan.data === null && payInfo === null) {
      return (
        <div className="loading">
          <div className="loader"></div>
        </div>
      );
    } else {
      // 無制限チケット あり
      if (plan.data["0"]["unlimited"] === true) {
        return (
          <>
            <div className="ticket_wrap">
              <div className="ticket">
                <div className="ticket_inr">
                  1日無制限チケット　適用中
                  <br />
                  有効期限：翌朝8時まで（{
                    plan.data["0"]["cancel_subscription"]
                  }{" "}
                  8時まで）
                </div>
              </div>
            </div>
          </>
        );
        // 無制限チケット なし
      } else {
        // サブスク あり
        if (plan.data["0"]["plan_name"] === "サブスクプラン") {
          return (
            <>
              {PaymentSection()}
              <div className="ticket_plan">
                <div className="ticket_inr">
                  <div className="text_wrap">
                    <p>10枚チケット</p>
                  </div>
                  <p className="price">
                    <span>￥</span>400
                  </p>
                  <button
                    onClick={openModal}
                    className="btn_important"
                    data-name="10枚チケット"
                    data-price="<span>￥</span>400"
                    data-plan="10枚チケット"
                    disabled={showPaymentInputs ? true : false}
                  >
                    購入
                  </button>
                </div>
              </div>
              <div className="ticket_plan">
                <div className="ticket_inr">
                  <div className="text_wrap">
                    <p>30枚チケット</p>
                  </div>
                  <p className="price">
                    <span>￥</span>1,000
                  </p>
                  <button
                    onClick={openModal}
                    className="btn_important"
                    data-name="30枚チケット"
                    data-price="<span>￥</span>1,000"
                    data-plan="30枚チケット"
                    disabled={showPaymentInputs ? true : false}
                  >
                    購入
                  </button>
                </div>
              </div>
              <div className="ticket_plan">
                <div className="ticket_inr">
                  <div className="text_wrap">
                    <p>1日無制限チケット</p>
                    <p className="remark">
                      ※何時に開始しても朝8時に自動で停止します。
                    </p>
                  </div>
                  <p className="price">
                    <span>￥</span>2,000<span>／1日</span>
                  </p>
                  <button
                    onClick={openModal}
                    className="btn_important"
                    data-name="1日無制限チケット"
                    data-price="<span>￥</span>2,000<span>／1日</span>"
                    data-plan="無制限チケット"
                    disabled={showPaymentInputs ? true : false}
                  >
                    購入
                  </button>
                </div>
              </div>
            </>
          );
          // サブスク なし
        } else {
          return (
            <>
              {PaymentSection()}
              <div className="ticket_plan">
                <div className="ticket_inr">
                  <div className="text_wrap">
                    <p>サブスクプラン（毎日6回）</p>
                    <p className="remark">
                      ※毎日6回分の占い回数が、朝8時に追加されます。
                      <br />
                      ※追加の占いチケットも購入できます。
                    </p>
                  </div>
                  <p className="price">
                    <span>￥</span>500<span>／月</span>
                  </p>
                  <button
                    onClick={openModal}
                    className="btn_important"
                    data-name="サブスクプラン（毎日6回）"
                    data-price="<span>￥</span>500<span>／月</span>"
                    data-plan="サブスクプラン"
                    disabled={
                      !plan.data["0"]["card"] || showPaymentInputs
                        ? true
                        : false
                    }
                  >
                    購入
                  </button>
                </div>
              </div>
              <div className="ticket_plan">
                <div className="ticket_inr">
                  <div className="text_wrap">
                    <p>1日無制限チケット</p>
                    <p className="remark">
                      ※何時に開始しても朝8時に自動で停止します。
                    </p>
                  </div>
                  <p className="price">
                    <span>￥</span>2,000<span>／1日</span>
                  </p>
                  <button
                    onClick={openModal}
                    className="btn_important"
                    data-name="1日無制限チケット"
                    data-price="<span>￥</span>2,000<span>／1日</span>"
                    data-plan="無制限チケット"
                    disabled={
                      !plan.data["0"]["card"] || showPaymentInputs
                        ? true
                        : false
                    }
                  >
                    購入
                  </button>
                </div>
              </div>
            </>
          );
        }
      }
    }
  };

  const ModalSection = () => {
    return (
      <Modal isOpen={modalIsOpen}>
        <div className="ticket_plan">
          <div className="ticket_inr">
            <p>{selectedName}</p>
            <p className="price">{parse(selectedPrice)}</p>
          </div>
        </div>
        {buttonFlg ? (
          <>
            <p className="center">購入を確定します</p>

            <div className="btn_wrap">
              <button onClick={handleSubmit} className="btn_important">
                OK
              </button>
              <button onClick={closeModal} className="btn_secondary">
                キャンセル
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="center">処理中…</p>
            <div className="loading">
              <div className="loader"></div>
            </div>
          </>
        )}
      </Modal>
    );
  };

  return (
    <>
      {TicketSection()}
      <div className="content_wrap" style={{ justifyContent: "center" }}>
        <p className="small font_normal">
          ※サブスクプランを解約しても、残った占いチケットは利用可能です。
          <br />
          ※占いチケットの有効期限は最終購入月から6ヶ月間です。
          <br />
          　占いチケットの譲渡・換金・払い戻しは出来ません。
          <br />
          ※「占える回数」が残っている場合は、占いチケットより優先して消費されます。
        </p>
      </div>
      {ModalSection()}
    </>
  );
};
