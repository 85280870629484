import { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import { db } from "../../firebase/firebase";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

import { HeadBlock } from "../components/HeadBlock";
import { getDocs } from "firebase/firestore";
import { Footer } from "../components/Footer";
import { getProfile } from "../../hooks/query";
import { Header } from "../components/Header";
import { toast } from "react-toastify";
import Modal from "react-modal";
import parse from "html-react-parser";
import { unlimitedDay } from "../../utils/functions";
export const Checkout = () => {
  const [data, setData] = useState(null);
  const { user } = useAuthContext();
  const [cansel, setCancel] = useState("");
  const [buttonFlg, setButtonFlg] = useState(true);
  const demoElement = useRef(null);
  const navigation = useNavigate();

  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedName, setSelectName] = useState(null);
  const [selectedPrice, setSelectPrice] = useState("");
  const [selectedPlan, setSelectPlan] = useState(null);

  useEffect(() => {
    const profile = getProfile(user.uid);
    getDocs(profile).then((querySnapshot) => {
      if (querySnapshot.size === 0) {
        navigation("/profile");
      }
    });
    const fetchData = async () => {
      const collectionRef = db
        .collection("plan")
        .where("userId", "==", user.uid);
      const snapshot = await collectionRef.get();
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(documents);
    };
    const loadScriptAndFetchData = async () => {
      await fetchData();

      const script = document.createElement("script");
      script.src = "https://js.pay.jp/v2/pay.js";
      script.async = true;
      script.onload = async () => {
        await createPayForm();
      };
      document.body.appendChild(script);
    };

    loadScriptAndFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createPayForm = async () => {
    if (window.Payjp && demoElement.current) {
      const payjp = window.Payjp("pk_live_ea9f610862a9060461dbf41f");

      const elements = payjp.elements();
      const cardElement = elements.create("card");

      cardElement.mount("#v2-demo");

      demoElement.current.addEventListener("submit", async (e) => {
        e.preventDefault();
        setButtonFlg(false);

        const token = await payjp.createToken(cardElement);

        try {
          const subscription = firebase.functions().httpsCallable("CardToken");
          const result = await subscription({
            token: token.id,
            email: user.email,
            uid: user.uid,
          });
          if (result.data == null) {
            toast.error("登録に失敗しました。もう一度入力をお願いします");
            setButtonFlg(true);
          } else {
            toast.success("カードの登録が完了しました。");
            window.location.reload();
          }
        } catch (error) {
          setButtonFlg(true);
          toast.error("登録に失敗しました。もう一度入力をお願いします");
        }
      });
    }
  };

  const openModal = async (e) => {
    e.preventDefault();
    if (
      e.currentTarget.dataset["plan"] === "解約" &&
      (!cansel || !cansel.match(/\S/g))
    ) {
      toast.error("解約理由を入力してください");
      return;
    }
    setSelectName(e.currentTarget.dataset["name"]);
    setSelectPrice(e.currentTarget.dataset["price"]);
    setSelectPlan(e.currentTarget.dataset["plan"]);
    setIsOpen(true);
  };

  const closeModal = async (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonFlg(false);

    try {
      const subscriptionCancel = firebase
        .functions()
        .httpsCallable("subscriptionCancel");
      const result = await subscriptionCancel({
        card: data[0].planId,
        uid: user.uid,
        cansel: cansel,
      });
      if (result.data == null) {
        toast.error("解約処理に失敗しました。もう一度入力をお願いします");
        setIsOpen(false);
        setButtonFlg(true);
      } else {
        setIsOpen(false);
        setButtonFlg(true);
        navigation("/home", { state: { message: "解約処理が完了しました" } });
      }
    } catch (error) {
      toast.error("解約処理に失敗しました。もう一度入力をお願いします");
      setIsOpen(false);
      setButtonFlg(true);
    }
  };

  const handleSubScription = async (e) => {
    e.preventDefault();
    setButtonFlg(false);

    try {
      const cancel_sub = unlimitedDay();
      const subscription = firebase
        .functions()
        .httpsCallable("UnlimitedTicket");
      const result = await subscription({
        uid: user.uid,
        status: "サブスクプラン",
        ticket: data[0].adding_ticket,
        cancel_sub: cancel_sub,
        customer: data[0].customer,
      });
      if (result.data == null) {
        toast.error("決済に失敗しました。もう一度入力をお願いします");
        setIsOpen(false);
        setButtonFlg(true);
      } else {
        setIsOpen(false);
        setButtonFlg(true);
        navigation("/home", { state: { message: "購入が完了しました" } });
      }
    } catch (error) {
      setIsOpen(false);
      setButtonFlg(true);
      toast.error("決済に失敗しました。もう一度入力をお願いします");
    }
  };

  const handleResume = async (e) => {
    e.preventDefault();
    setButtonFlg(false);

    try {
      const subscription = firebase
        .functions()
        .httpsCallable("subscriptionResume");
      const result = await subscription({
        card: data[0].planId,
        uid: user.uid,
      });
      if (result.data == null) {
        toast.error("決済に失敗しました。もう一度入力をお願いします");
        setIsOpen(false);
        setButtonFlg(true);
      } else {
        setIsOpen(false);
        setButtonFlg(true);
        navigation("/home", { state: { message: "決済が再開されました" } });
      }
    } catch (error) {
      setIsOpen(false);
      setButtonFlg(true);
      toast.error("決済に失敗しました。もう一度入力をお願いします");
    }
  };

  const Section = () => {
    if (data === null) {
      return (
        <div className="loading">
          <div className="loader"></div>
        </div>
      );
    } else if (data[0].plan_status === "解約予定") {
      return (
        <>
          <div className="ticket_plan">
            <div className="ticket_inr">
              <div className="text_wrap">
                <p>サブスクプラン（毎日6回、月額500円）</p>
                <p>有効期限：{data[0].plan_end}まで</p>
                <p className="remark">
                  ※毎日6回分の占い回数が、朝8時に追加されます。
                  <br />
                  ※追加の占いチケットも購入できます。
                </p>
              </div>
              <p className="price">
                <span>￥</span>500<span>／月</span>
              </p>
              <button
                onClick={openModal}
                className="btn_important"
                data-name="サブスクプラン（毎日6回）"
                data-price="<span>￥</span>500<span>／月</span>"
                data-plan="再開"
              >
                再開
              </button>
            </div>
          </div>
        </>
      );
    } else if (data[0].card && data[0].plan_name === "無料プラン") {
      return (
        <>
          <div className="ticket_plan">
            <div className="ticket_inr">
              <div className="text_wrap">
                <p>サブスクプラン（毎日6回、月額500円）</p>
                <p className="remark">
                  ※毎日6回分の占い回数が、朝8時に追加されます。
                  <br />
                  ※追加の占いチケットも購入できます。
                </p>
              </div>
              <p className="price">
                <span>￥</span>500<span>／月</span>
              </p>
              <button
                onClick={openModal}
                className="btn_important"
                data-name="サブスクプラン（毎日6回）"
                data-price="<span>￥</span>500<span>／月</span>"
                data-plan="サブスクプラン"
              >
                購入
              </button>
            </div>
          </div>
        </>
      );
    } else if (data[0].card && data[0].plan_name === "サブスクプラン") {
      return (
        <>
          <div className="content_wrap">
            <p className="caption">現在のプラン</p>
            <div className="content_inr">
              <p>サブスクプラン（毎日6回、月額500円）</p>
            </div>
          </div>
          <p className="center">
            サブスクプランを解約すると、無料会員になります。
            <br />
            請求停止は翌月分からの反映となり、月内はそのまま利用可能です。
            <br />
            解約を希望する場合はその理由をお伝えください。
            <br />
            また、解約後もサブスクプランに再度お申し込みができます。
          </p>
          <div className="form_wrap mt20 w100">
            <label>解約理由</label>
            <textarea
              value={cansel}
              onChange={(e) => setCancel(e.target.value)}
            />
          </div>
          <div className="btn_wrap">
            <button
              onClick={openModal}
              className="btn_important"
              data-name="サブスクプラン解約"
              data-price=""
              data-plan="解約"
            >
              解約
            </button>
            <button
              onClick={() => navigation("/mypage")}
              className="btn_secondary"
            >
              キャンセル
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p className="center">
            クレジット情報を「登録」してから、ご希望のプランの「購入」ボタンを押してください。
          </p>
          <div className="payment_wrap">
            <form ref={demoElement}>
              <div id="v2-demo" className="payment_form"></div>
              <p className="note">
              ＊CVC番号は、クレジットカード番号とは別に印字されている3桁または4桁の数字です。
              </p>
              <img src="/images/img_cvc.png" className="credit" alt="＊CVC番号は、クレジットカード番号とは別に印字されている3桁または4桁の数字です。" />
              {buttonFlg ? (
                <div className="btn_wrap">
                  <button type="submit" className="btn_important">
                    登録
                  </button>
                </div>
              ) : (
                <div className="btn_wrap">
                  <div className="btn_loading">
                    <div className="loader"></div>
                  </div>
                </div>
              )}
            </form>
            <img src="/images/img_credit.png" className="credit" alt="" />
            <img src="/images/logo_payjp.png" alt="" />
          </div>
          <div className="ticket_plan">
            <div className="ticket_inr">
              <div className="text_wrap">
                <p>サブスクプラン（毎日6回）</p>
                <p className="remark">
                  ※毎日6回分の占い回数が、朝8時に追加されます。
                  <br />
                  ※追加の占いチケットも購入できます。
                </p>
              </div>
              <p className="price">
                <span>￥</span>500<span>／月</span>
              </p>
              <button
                onClick={openModal}
                className="btn_important"
                data-name="サブスクプラン（毎日6回）"
                data-price="<span>￥</span>500<span>／月</span>"
                data-plan="サブスクプラン"
                disabled={!data[0].card ? true : false}
              >
                購入
              </button>
            </div>
          </div>
        </>
      );
    }
  };

  const ModalSection = () => {
    return (
      <Modal isOpen={modalIsOpen}>
        {selectedPlan === "サブスクプラン" ? (
          <>
            <div className="ticket_plan">
              <div className="ticket_inr">
                <p>{selectedName}</p>
                <p className="price">{parse(selectedPrice)}</p>
              </div>
            </div>
            {buttonFlg ? (
              <>
                <p className="center">購入を確定します</p>
                <div className="btn_wrap">
                  <button
                    onClick={handleSubScription}
                    className="btn_important"
                  >
                    OK
                  </button>
                  <button onClick={closeModal} className="btn_secondary">
                    キャンセル
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="center">処理中…</p>
                <div className="loading">
                  <div className="loader"></div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {selectedPrice ? (
              <>
                <div className="ticket_plan">
                  <div className="ticket_inr">
                    <p>{selectedName}</p>
                    <p className="price">{parse(selectedPrice)}</p>
                  </div>
                </div>
                {buttonFlg ? (
                  <>
                    <p className="center">サブスクプランを再開します</p>
                    <div className="btn_wrap mt30">
                      <button onClick={handleResume} className="btn_important">
                        OK
                      </button>
                      <button onClick={closeModal} className="btn_secondary">
                        キャンセル
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="center">処理中…</p>
                    <div className="loading">
                      <div className="loader"></div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {buttonFlg ? (
                  <>
                    <p className="center">サブスクプランを解約しますか？</p>
                    <div className="btn_wrap mt30">
                      <button onClick={handleSubmit} className="btn_important">
                        OK
                      </button>
                      <button onClick={closeModal} className="btn_secondary">
                        キャンセル
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="center">処理中…</p>
                    <div className="loading">
                      <div className="loader"></div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    );
  };

  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <HeadBlock title="サブスクプラン管理" />
        <Header />
        <div className="wrap">
          {Section()}
          <div
            className="content_wrap mt30"
            style={{ justifyContent: "center" }}
          >
            <p className="small font_normal">
              ※サブスクプランを解約しても、残った占いチケットは利用可能です。
              <br />
              ※占いチケットの有効期限は最終購入月から6ヶ月間です。
              <br />
              　占いチケットの譲渡・換金・払い戻しは出来ません。
              <br />
              ※「占える回数」が残っている場合は、占いチケットより優先して消費されます。
            </p>
          </div>
          {ModalSection()}
        </div>
        <Footer />
      </>
    );
  }
};
