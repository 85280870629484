import { useState } from "react";
import TwitterButton from "./TwitterButton";
import { FaImage } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import html2canvas from "html2canvas";

function TextOverlay({ url, text, hashtag }) {
  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [dispPng, setDispPng] = useState(null);
  let shareText = "";
  let shareInrText = text;

  if (navigator.share) {

    // おためし占い
    if (hashtag) {
      const shareInrLength = 85 - hashtag.length;
      shareInrText = text.slice(0, shareInrLength) + "…";
      shareText = "【 " + hashtag + " 】\n『AI占い by チャットGPT』で占いました！\n\n" + shareInrText + "…\n\n#AI占い #占い #今日のおためし占い\n" + url;
    // 通常の占い
    } else {
      if (text.length > 98) {
        shareInrText = text.slice(0, 98) + "…";
      }
      shareText = "『AI占い by チャットGPT』で占いました。\n\n【" + shareInrText + "】\n\n#AI占い #占い\n" + url;
    }
  }

  const closeModal = async (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const ModalSection = () => {
    return (
      <Modal isOpen={modalIsOpen}>
        <button onClick={closeModal} className="btn_close">
          <IoClose />
        </button>
        {dispPng ? (
          <img src={dispPng} alt="AI占い結果" className="mt30" />
        ) : (
          <div className="loading">
            <div className="loader"></div>
          </div>
        )}
        {isMobile && <p className="center remark">※画像を長押しで保存</p>}
        <div className="btn_wrap">
          <button onClick={handleSharing} className="btn_share_image">
            <FaImage className="btn_iconfont" />
            {(isMobile && navigator.share) ? "シェア先を選ぶ" : "画像をダウンロード"}
          </button>
          {(!isMobile && !navigator.share) && (
            <TwitterButton
              url="ai-uranai.jp"
              text={text}
              hashtag={hashtag ? hashtag : ""}
            />
          
          )}
        </div>
      </Modal>
    );
  };

  const generateImage = async (e) => {
    setIsOpen(true);
    let imgTarget = "";
    let clone_element = "";
    let imgTargetClone = "";
    let textHeight = 0;
    // おためし占い
    if (hashtag) {
      imgTarget = e.target.closest(".lp_trial_wrap");
      clone_element = imgTarget.cloneNode(true);
      clone_element.id = "trial_capture";
      clone_element.querySelector(".theme").querySelector("p").innerHTML = toZenkaku(clone_element.querySelector(".theme").querySelector("p").innerHTML);
      clone_element.querySelector(".theme").querySelector(".sub").innerHTML = toZenkaku(clone_element.querySelector(".theme").querySelector(".sub").innerHTML);
      clone_element.querySelector(".answer").innerHTML = "<p>" + clone_element.querySelector(".answer").innerHTML + "</p>";
      clone_element.querySelector(".answer_wrap").firstElementChild.remove();
      clone_element.querySelector(".answer_wrap").lastElementChild.remove();
      clone_element.insertAdjacentHTML('beforeend', '<div class="footer"><img src="/images/logo_share.png" /></div>');
      document.querySelector("#root").after(clone_element);
      imgTargetClone = document.querySelector("#trial_capture");
     // テキスト自体の高さ取得
     textHeight = imgTargetClone.querySelector(".answer").querySelector("p").offsetHeight;
     console.log(textHeight);
     // テキストがオーバーする場合は画像を表示
     if (816 < textHeight) {
       imgTargetClone.querySelector(".answer").querySelector("p").insertAdjacentHTML('beforeend', '<img src="/images/img_omit.png" class="is_hidden" />');
     }
    // 通常の占い
  } else {
      imgTarget = e.target.closest(".answer_wrap").querySelector(".answer");
      clone_element = imgTarget.cloneNode(true);
      clone_element.id = "capture";
      clone_element.innerHTML = "<div class='inr'><p>" + clone_element.innerHTML + "</p></div>";
      clone_element.insertAdjacentHTML('beforeend', '<div class="footer"><img src="/images/logo_share.png" /></div>');
      document.querySelector("#root").after(clone_element);
      imgTargetClone = document.querySelector("#capture");
     // テキスト自体の高さ取得
     textHeight = imgTargetClone.querySelector("p").offsetHeight;
     console.log(textHeight);
     // テキストがオーバーする場合は画像を表示
     if (1008 < textHeight) {
       imgTargetClone.querySelector("p").insertAdjacentHTML('beforeend', '<img src="/images/img_omit.png" class="is_hidden" />');
     }
    }

    await html2canvas(imgTargetClone).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      setDispPng(imgData);
      // おためし占い
      if (hashtag) {
        document.querySelector("#trial_capture").remove();
      // 通常の占い
      }  else {
        document.querySelector("#capture").remove();
      }
    });
  };

  const toBlob = (base64) => {
    const decodedData = atob(base64.replace(/^.*,/, ""));
    const buffers = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      buffers[i] = decodedData.charCodeAt(i);
    }
    try {
      const blob = new Blob([buffers.buffer], {
        type: "image/png",
      });
      return blob;
    } catch (e) {
      return null;
    }
  };

  const handleSharing = async () => {
    // モバイルでシェア機能を利用可能な場合は画像つきでシェアさせる
    if (isMobile && navigator.share) {
      const blob = toBlob(dispPng);

      const imageFile = new File([blob], "image.png", {
        type: "image/png",
      });

      try {
        await navigator.share({
          text: shareText,
          files: [imageFile],
        });
      } catch (error) {
        console.error(error);
      }

    // Chromeなどのシェア機能を利用できない場合はポップアップ表示する
    } else {
      const downloadLink = document.createElement("a");

      if (typeof downloadLink.download === "string") {
        downloadLink.href = dispPng;
        // ファイル名
        downloadLink.download = "AI占い結果.png";
        // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
        document.body.appendChild(downloadLink);
        // ダウンロードリンクが設定された a タグをクリック
        downloadLink.click();
        // Firefox 対策で追加したリンクを削除しておく
        document.body.removeChild(downloadLink);
      } else {
        window.open(dispPng);
      }
    }
  };

  // 半角英数字の文字が重なる対策として、全角に変換する
  const toZenkaku = t => t
  .replace(/[#A-Za-z0-9^/s]/g, x => String.fromCharCode(x.charCodeAt(0) + 0xFEE0));

  return (
    <>
      <button onClick={generateImage} className="btn_share_image">
        <FaImage className="btn_iconfont" />
        結果画像をシェア
      </button>
      {ModalSection()}
    </>
  );
}

export default TextOverlay;
