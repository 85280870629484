import { db } from "../../firebase/firebase";
import firebase from "firebase/compat/app";
import { useNavigate, Navigate, useLocation, NavLink } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { useEffect, useState, useRef } from "react";
import "firebase/compat/firestore";
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
import { Header } from "../components/Header";
import { HeadBlock } from "../components/HeadBlock";
import { TicketForm } from "../components/TicketForm";
import { Footer } from "../components/Footer";
import { getProfile } from "../../hooks/query";
import { toast } from "react-toastify";
import TextOverlay from "../components/TextOverlay";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { isMobile } from "react-device-detect";
import { currentMonth, message } from "../../utils/constans";
import { formatDate, getToday, sanitizeInput } from "../../utils/functions";
import { IoClose } from "react-icons/io5";
import { IoMdHelpCircle, IoMdArrowDropright } from "react-icons/io";
import Modal from "react-modal";

export const Home = () => {
  const navigation = useNavigate();
  const { user } = useAuthContext();
  const [fortune, setFortune] = useState(null);
  const [question, setQuestion] = useState(null);
  const [payInfo, setPayInfo] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [answerQuestion, setAnswerQuestion] = useState(null);
  const [answerFortune, setAnswerFortune] = useState(null);
  const [buttonFlg, setButtonFlg] = useState(true);

  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = useState(false);

  const [res, setRes] = useState(null);
  const location = useLocation();
  const { state } = location;

  const toastId = useRef(null);

  useEffect(() => {
    const fetchPayInfo = async () => {
      try {
        const profile = getProfile(user.uid);

        getDocs(profile).then((querySnapshot) => {
          if (querySnapshot.size === 0) {
            navigation("/profile");
          }
        });

        const planQuerySnapshot = await firebase
          .firestore()
          .collection("plan")
          .where("userId", "==", user.uid)
          .get();

        const newData = planQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          adding_ticket: doc.get("adding_ticket"),
          plan_name: doc.get("plan_name"),
          question_times: doc.get("question_times"),
          created_at: doc.get("created_at"),
          updated_at: doc.get("updated_at"),
          card: doc.get("card"),
          unlimited: doc.get("unlimited"),
          cancel_subscription: doc.get("cancel_subscription"),
        }));

        setPayInfo(newData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPayInfo();
    if (toastId.current) {
      toast.update(toastId.current, { autoClose: 3000 });
    } else if (state) {
      toastId.current = toast.success(state.message, {
        autoClose: 3000,
      });
    }

    if (answer) {
      const answerScrollTop =
        document.getElementById("answer").getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        document.querySelector("header").clientHeight -
        110;
      window.scrollTo({
        behavior: "smooth",
        top: answerScrollTop,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, answer]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!fortune || !question || !question.match(/\S/g)) {
      toast.error("占うカテゴリとお悩みを入力してください");
      return;
    }

    setButtonFlg(false);
    setAnswer(null);
    document
      .getElementById("fortune")
      .scrollIntoView({ behavior: "smooth", block: "center" });

    const q = query(
      collection(db, "profiles"),
      where("userId", "==", user.uid)
    );

    const q2 = query(collection(db, "plan"), where("userId", "==", user.uid));
    // eslint-disable-next-line no-unused-vars
    const [profilesSnapshot, otherSnapshot] = await Promise.all([
      getDocs(q),
      getDocs(q2),
    ]);

    getDocs(q).then((querySnapshot) => {
      // データが存在する場合の処理
      const profile = [];
      querySnapshot.forEach(async (doc) => {
        const birthDate = doc.get("birthDate");
        const formattedBirthDate = formatDate(birthDate);
        profile.push({
          nickName: doc.get("nickName"),
          birthDate: formattedBirthDate,
          constellation: doc.get("constellation"),
          gender: doc.get("gender"),
          introduction: doc.get("introduction"),
        });

        const dataQuery = await query(
          collection(db, "fortune_data"),
          where("month", "==", currentMonth),
          where("sign", "==", profile[0].constellation),
          where("category", "==", fortune)
        );

        const dataAllQuery = query(
          collection(db, "fortune_data"),
          where("month", "==", currentMonth),
          where("sign", "==", profile[0].constellation)
        );

        const fortuneAllData = [];
        await getDocs(dataAllQuery)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              fortuneAllData.push(doc.data());
            });
          })
          .catch((error) => {
            console.log(error);
          });

        // 恋愛運、仕事運、金運のスコアを抽出
        const loveScore = fortuneAllData.find(
          (item) => item.category === "恋愛運"
        ).status;
        const workScore = fortuneAllData.find(
          (item) => item.category === "仕事運"
        ).status;
        const moneyScore = fortuneAllData.find(
          (item) => item.category === "金運"
        ).status;

        // 全体運を算出
        const overallFortune = (loveScore + workScore + moneyScore) / 3;
        const roundedOverallFortune = Math.round(overallFortune * 10) / 10;

        let allFlg = "";
        if (roundedOverallFortune === undefined) {
          allFlg = "やや好調";
        } else if (
          roundedOverallFortune === 0 ||
          roundedOverallFortune === "0"
        ) {
          allFlg = "絶好調";
        } else if (
          roundedOverallFortune === 1 ||
          roundedOverallFortune === "1"
        ) {
          allFlg = "やや好調";
        } else if (
          roundedOverallFortune === 2 ||
          roundedOverallFortune === "2"
        ) {
          allFlg = "停滞気味";
        }

        const fortuneData = [];

        await getDocs(dataQuery)
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              fortuneData.push(doc.data());
            });
          })
          .catch((error) => {
            console.log(error);
          });

        let flg = "";
        if (fortuneData[0] === undefined) {
          flg = "やや好調";
        } else if (
          fortuneData[0].status === 0 ||
          fortuneData[0].status === "0"
        ) {
          flg = "絶好調";
        } else if (
          fortuneData[0].status === 1 ||
          fortuneData[0].status === "1"
        ) {
          flg = "やや好調";
        } else if (
          fortuneData[0].status === 2 ||
          fortuneData[0].status === "2"
        ) {
          flg = "停滞気味";
        }

        if (fortuneData[0] !== undefined) {
          setRes(fortuneData[0].sentence);
        }

        const sanitizedInput = sanitizeInput(question);

        const today = getToday();
        let text = "";
        if (fortune === "全体運") {
          text = `相談内容は以下の通りです。相談者の名前：${profile[0].nickName}。性別：${profile[0].gender}。生年月日：${profile[0].birthDate}。今日の日付：${today}。相談内容：「${sanitizedInput}」。占いの結果 ホロスコープによると、今のあなたの運勢は${allFlg}です。`;

          if (profile[0].introduction) {
            text += `ユーザーの情報：${profile[0].introduction}`;
          }
        } else {
          text = `相談内容は以下の通りです。相談者の名前：${profile[0].nickName}。性別：${profile[0].gender}。生年月日：${profile[0].birthDate}。今日の日付：${today}。相談内容：「${sanitizedInput}」。占いの結果 ホロスコープによると、今のあなたの${fortune}は${flg}です。`;
          if (profile[0].introduction) {
            text += `ユーザーの情報：${profile[0].introduction}`;
          }
        }

        const postChat = firebase.functions().httpsCallable("chatGpt");

        const response = await postChat({
          message: message,
          text: text,
        });

        setAnswerQuestion(question);
        setAnswer(response.data.message.content);
        setAnswerFortune(fortune);
        setFortune(null);
        setQuestion(null);

        if (!response.data.message.content) {
          toast.error("占いの結果が取得できませんでした。");
          return;
        }
        await addDoc(collection(db, "fortunes"), {
          question_content: question,
          category: fortune,
          reply_detail: response.data.message.content,
          userId: user.uid,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const otherData = otherSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const docRef = db.collection("plan").doc(otherData["0"]["id"]);
        const updateFunction = async (transaction) => {
          const doc = await transaction.get(docRef);
          const data = doc.data();

          if (data.unlimited === true) {
            transaction.update(docRef, {
              question_times: data.question_times,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            });
          } else if (
            otherData[0].question_times === 0 &&
            otherData[0].adding_ticket < -1
          ) {
            return;
          } else if (
            otherData[0].question_times === 0 &&
            otherData[0].adding_ticket === 0
          ) {
            return;
          } else if (data.question_times === 0) {
            if (data.adding_ticket === 0) {
              return;
            } else {
              transaction.update(docRef, {
                adding_ticket: data.adding_ticket - 1,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
              });
            }
          } else {
            transaction.update(docRef, {
              question_times: data.question_times - 1,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
        };

        try {
          await db.runTransaction(updateFunction);
          setPayInfo((prevPayInfo) => {
            const newPayInfo = [...prevPayInfo];
            if (otherData[0].unlimited) {
              newPayInfo[0].question_times = otherData[0].question_times;
            } else if (
              otherData[0].question_times === 0 &&
              otherData[0].adding_ticket < -1
            ) {
              return;
            } else if (
              otherData[0].question_times === 0 &&
              otherData[0].adding_ticket === 0
            ) {
              return;
            } else if (otherData[0].question_times === 0) {
              if (otherData[0].adding_ticket === 0) {
                return;
              } else {
                newPayInfo[0].adding_ticket = otherData[0].adding_ticket - 1;
              }
            } else {
              newPayInfo[0].question_times = otherData[0].question_times - 1;
            }
            return newPayInfo;
          });
          setButtonFlg(true);
        } catch (error) {
          console.error("Transaction failed:", error);
        }
      });
    });
  };

  const handleOptionChange = (e) => {
    setFortune(e.target.value);
  };

  const fotuneExample = (value) => {
    switch (value) {
      case "恋愛運":
        return (
          <div className="example category_love">
            <span className="head">質問の書き方例</span>
            <span>
              ずっと一緒にいたいと思える運命のパートナーに出会いたい！　相手の特徴は？
            </span>
            <button onClick={openModal}>▶ さらに質問例をチェック</button>
          </div>
        );
      case "仕事運":
        return (
          <div className="example category_work">
            <span className="head">質問の書き方例</span>
            <span>
              職場の人間関係に不満があります。条件も良くありません。転職すべきですか？
            </span>
            <button onClick={openModal}>▶ さらに質問例をチェック</button>
          </div>
        );
      case "金運":
        return (
          <div className="example category_money">
            <span className="head">質問の書き方例</span>
            <span>
              あと100万円くらい年収を増やしたい。どんな副業をすると良い？
            </span>
            <button onClick={openModal}>▶ さらに質問例をチェック</button>
          </div>
        );
      case "全体運":
        return (
          <div className="example category_other">
            <span className="head">質問の書き方例</span>
            <span>
              信じていた人に裏切られて、もう何も信じられない。どうすればいい？
            </span>
            <button onClick={openModal}>▶ さらに質問例をチェック</button>
          </div>
        );

      default:
        return;
    }
  };

  const fortuneForm = () => {
    if (payInfo == null) {
      return;
    } else if (
      payInfo != null &&
      payInfo[0].question_times === 0 &&
      payInfo[0].adding_ticket === 0 &&
      payInfo[0].unlimited === false
    ) {
      return (
        <>
          <div className="payment_wrap">
            <p className="big center">
              占える回数と占いチケットが、ゼロになりました。
              <br />
              {payInfo[0].plan_name === "無料プラン" &&
                "サブスクプランに加入するか、"}
              追加チケットを購入してください。
            </p>
          </div>
          <TicketForm data={payInfo} />
        </>
      );
    } else {
      return (
        <>
          {buttonFlg ? (
            <form id="fortune" className="fortuneForm" onSubmit={handleSubmit}>
              <p>占うカテゴリを選んでください</p>
              <div className="radio_wrap">
                <input
                  type="radio"
                  id="category_love"
                  value="恋愛運"
                  checked={fortune === "恋愛運"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="category_love"
                  className="radio_label category_love"
                >
                  恋愛
                </label>
                <input
                  type="radio"
                  id="category_work"
                  value="仕事運"
                  checked={fortune === "仕事運"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="category_work"
                  className="radio_label category_work"
                >
                  仕事
                </label>
                <input
                  type="radio"
                  id="category_money"
                  value="金運"
                  checked={fortune === "金運"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="category_money"
                  className="radio_label category_money"
                >
                  お金
                </label>
                <input
                  type="radio"
                  id="category_other"
                  value="全体運"
                  checked={fortune === "全体運"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="category_other"
                  className="radio_label category_other"
                >
                  その他
                </label>
              </div>
              <p>あなたのお悩みを聞かせてください</p>
              {fotuneExample(fortune)}
              <textarea
                value={question ?? ""}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="※最大200文字。お悩みを詳しく書くと、より具体的な占い結果をお伝えできます。"
                maxLength={200}
              />
              <button type="submit" className="btn_gradient">
                送信
              </button>
            </form>
          ) : (
            <div className="movie_loading">
              <div className="movie_inr">
                <video
                  src="/images/mov_loading.mp4"
                  poster="/images/img_mov_loading.png"
                  muted
                  autoPlay
                  loop
                  playsInline
                ></video>
                <p>鑑定中…</p>
              </div>
              <p className="remark">
                ※10〜20秒ほど、画面移動せずにお待ちください。
                <br />
                ※アクセス集中時には、遅くなることがあります。
              </p>
            </div>
          )}
        </>
      );
    }
  };

  const Section = () => {
    if (payInfo === null) {
      return (
        <div className="loading">
          <div className="loader"></div>
        </div>
      );
    } else if (payInfo[0].unlimited === true) {
      return (
        <div className="ticket_wrap">
          <div className="ticket">
            <div className="ticket_inr">
              1日無制限チケット　適用中
              <br />
              有効期限：翌朝8時まで（{payInfo[0].cancel_subscription} 8時まで）
            </div>
          </div>
        </div>
      );
    } else if (payInfo != null) {
      return (
        <div className="ticket_wrap">
          <div className="ticket">
            <div className="ticket_inr">
              占える回数{isMobile ? <br /> : "　"}
              {payInfo ? payInfo[0].question_times : 0}　回
            </div>
          </div>
          <div className="ticket">
            <div className="ticket_inr">
              占いチケット{isMobile ? <br /> : "　"}
              {payInfo[0].adding_ticket}　枚
            </div>
          </div>
        </div>
      );
    }
  };

  // 質問例モーダルウィンドウ
  const ExamModalSection = () => {
    return (
      <Modal isOpen={modalIsOpen} className="exam_modal">
        <button onClick={closeModal} className="btn_close">
          <IoClose />
        </button>
        <div className="exam">
          <div className="head">
            <IoMdHelpCircle />
            <span>質問例</span>
            <IoMdArrowDropright />
            <span>こんなふうに質問してみてくださいね♪</span>
          </div>
          <div className="exam_inr category_love">
            <div className="head_small">恋　愛</div>
            <ul>
              <li>
                同じ職場ですが、違うチームで働いている男性に、1年くらい片思いしています。あまり話す機会がないのですが、挨拶くらいはする関係です。その人は、今私のことをどう思っていますか？恋愛対象としてみてくれていますか？
              </li>
              <li>
                今、私にはパートナーがいません。ずっと一緒にいたいと思えるような人に出会いたいです。そんな私の運命の相手の特徴を、３つ教えてください！
              </li>
              <li>
                今、好きな人には奥さんがいます。私はその人と恋愛関係にありますが、彼は奥さんと別れて私と一緒になると言いつつも、具体的な時期を教えてくれません。彼は奥さんといつ別れてくれますか？
              </li>
            </ul>
          </div>
          <div className="exam_inr category_work">
            <div className="head_small">仕　事</div>
            <ul>
              <li>
                職場の人間関係に不満があります。条件も良くありません。転職すべきですか？
              </li>
              <li>
                毎日残業でツラいです…。いまいち自分のやっていることが会社の売上に繋がっているという実感がないままに、業務ばかりが増えていきます。私はこの仕事でいつ評価されるのでしょうか？
              </li>
              <li>
                今私は事務員として働いています。でも、仕事に慣れてきたせいか、つまらなく感じています。違う仕事をしてみたい気持ちもあります。私が今後仕事に活かせる才能があるとしたら、それは何なのでしょうか？
              </li>
            </ul>
          </div>
          <div className="exam_inr category_money">
            <div className="head_small">お　金</div>
            <ul>
              <li>あと100万円年収を増やしたい。どんな副業をすると良い？</li>
              <li>
                毎月お金がギリギリなので、もっとお金が欲しいです。今している仕事の収入以外に、臨時収入はいつ入ってきそうですか？
              </li>
              <li>
                金運をアップさせるためには何をしたらいいですか？私は契約社員で、毎月給料はもらえてますが、少ないです。金運アップのための具体的な行動を教えてください。
              </li>
            </ul>
          </div>
          <div className="exam_inr category_other">
            <div className="head_small">その他</div>
            <ul>
              <li>
                頑張ってるのに、職場の上司にはいじめられるし、急な支出は続くし、好きな人には振られるし、どうして嫌なことばかり続くのですか？私の頑張りが報われる日は来ますか？
              </li>
              <li>
                信じていた人に裏切られて、もう何も信じられない。どうすればいい？
              </li>
              <li>
                自分の才能がわかりません。でも、才能を発揮して活躍している人に憧れます。私の才能は何ですか？そして、その才能を発揮して、私が本当に輝ける場所はどこにありますか？
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    );
  };

  const openModal = async (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeModal = async (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  // ここからHTML
  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <HeadBlock title="今すぐ占う" />
        <Header />
        <div id="top" className="wrap">
          {Section()}

          <NavLink to={"/profile/edit"}><img src="/images/btn_userprofile.png" alt="あなたのことをあらかじめ入力しておくとさらにくわしく正確な占いができます！ここから入力" className="btn_userprofile" /></NavLink>

          {fortuneForm()}

          {answer && (
            <div id="answer" className="answer_wrap">
              <p>それでは、答えていきますね。</p>
              <p>{answerQuestion}</p>
              <div className="answer">
                {answer.split("\n").map((detail, idx) => (
                  <span key={idx}>
                    {detail}
                    <br />
                  </span>
                ))}
              </div>
              <div className="btn_wrap">
                <TextOverlay url="https://ai-uranai.jp" text={answerQuestion} />
              </div>
              <AnchorLink href="#top" className="btn_gradient big">
                &nbsp;もう一度占う
              </AnchorLink>
              {answerFortune !== "全体運" && (
                <div className="column_wrap">
                  <div className="caption">
                    SAIKI先生から今月の{answerFortune}についてひとこと
                  </div>
                  <p>{res}</p>
                </div>
              )}
            </div>
          )}
          <p>
            ※占いの結果は、SAIKI先生による西洋占星術の運勢占いをベースに、AIによる解析とアドバイスを加えたものです。未来や真実はひとつではなく、あなたの行動や考え方で常に変わるものです。全ての占い結果は、成果を保証するものではなく、法令や公序良俗に反するいかなる行動も推奨しておりません。
          </p>

          <div className="saiki_profile_wrap">
            <div className="caption">監修者：占術師SAIKI</div>
            <img src="/images/img_saiki.png" alt="監修者：占術師SAIKI" />
            <p>
              大正時代から代々続く九星気学風水家系。幼い頃から両親に人生の方向性を占術で決められてきた。同時に占術を学ぶよう強いられるが反抗。しかし運命に導かれるように占術師としての道を歩むようになり、人生が好転。東洋占星術から西洋占星術まで幅広く扱う。
              <br />
              鑑定数約3000人。「背中を押してもらえた」「めちゃくちゃ当たってる」「心のこもった鑑定に涙が出ました」という声が日々寄せられている。
              <br />
              <a
                href="https://twitter.com/Saiki_Kantei"
                target="_blank"
                rel="noreferrer"
              >
                公式X（Twitter）はこちら
              </a>
            </p>
          </div>
        </div>
        {ExamModalSection()}
        <Footer />
      </>
    );
  }
};
