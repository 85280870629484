import React, { useState } from "react";

import { useAuthContext } from "../../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";
const AdminHome = () => {
  const { user } = useAuthContext();
  const [buttonFlg, setButtonFlg] = useState(true);
  const navigation = useNavigate();
  const handleLogout = () => {
    auth.signOut();
    navigation("/login");
  };
  const convertToCSV = (data) => {
    const header = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return header + rows;
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchDataAndExportToCSV = async () => {
    setButtonFlg(false);
    try {
      // Cloud Functionエンドポイントからユーザー情報を取得
      const response = await fetch(
        "https://us-central1-ai-app-284bb.cloudfunctions.net/getUsersCsv"
      );
      const users = await response.json();

      const csv = convertToCSV(users);
      const filename = "user_data.csv";
      downloadCSV(csv, filename);
      setButtonFlg(true);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  if (user.email === "admin@argyle.jp") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {buttonFlg ? (
          <button onClick={fetchDataAndExportToCSV}>CSVエクスポート</button>
        ) : (
          <p>送信中</p>
        )}
        <div>
          <button onClick={handleLogout}>ログアウト</button>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default AdminHome;
