import { Helmet } from "react-helmet-async";
import { useAdobeFonts } from "react-adobe-fonts";
import "../../styles/style.scss";

export const HeadBlock = (props) => {
  const { title } = props;
  useAdobeFonts({
    kitId: "wqf8kja",
  });
  return (
    <Helmet>
      <title>
        {title
          ? "AI占い by チャットGPT - " + title
          : "AI占い by チャットGPT - 日本初の本格AI占い（占術師SAIKI × ChatGPT）"}
      </title>
    </Helmet>
  );
};
