import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";

export const Specified = () => {
  return (
    <>
      <HeadBlock title="特定商取引法に基づく表記" />
      <HeaderNotLogin />
      <div className="wrap not_login">
        <div className="content_wrap">
          <h1 className="caption">特定商取引法に基づく表記</h1>
          <dl className="document">
            <dt>■販売事業者名</dt>
            <dd>アーガイル株式会社 / argyle Inc.</dd>
            <dt>■運営責任者名</dt>
            <dd>代表取締役　岡安 淳司</dd>
            <dt>■住所</dt>
            <dd>〒162-0814 東京都新宿区新小川町9-7 第三服部ビル3F</dd>
            <dt>■お問い合わせ</dt>
            <dd>
              「AI占い by
              チャットGPT」のご利用方法等に関するお問い合わせはお電話では承っておりません。サービスにログインいただき、お問い合わせフォームからお問い合わせください。ご利用前のご相談、OEM開発、取材などのご連絡は運営会社のサイトからお問い合わせください。本サービスに関する占い監修者へのお問い合わせは受け付けておりません。
              <br />※
              特定商取引法に基づき、取引時にご請求があれば遅滞なくメール等により電話番号をご連絡いたします
            </dd>
            <dt>■メールアドレス</dt>
            <dd>
              お問い合わせは、
              <a
                href="https://forms.gle/kvW4Mm9TwSfdcEJi7"
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </a>
              から
            </dd>
            <dt>■サービス利用料金</dt>
            <dd>
              <a href="/" target="_blank">
                公式サイト
              </a>
              、プラン選択ページ、または別途資料に表示しております。
              <br />
              各種キャンペーン等の特別プランについては、別途定める期間・料金の条件に準じます。
            </dd>
            <dt>■サービス提供内容</dt>
            <dd>
              <a href="/" target="_blank">
                公式サイト
              </a>
              、プラン選択ページ、または別途資料に表示しております。
              <br />
              月額有料サービスの契約期間は、1ヶ月単位の自動更新です。契約期間満了の前月末までに書面または電磁的方法によりプランの終了申請を行わない限り、選択済みのプランで1ヶ月づつ自動的に契約が延長されます。
            </dd>
            <dt>■支払いの方法</dt>
            <dd>
              クレジットカード決済。月末締め（請求日はご利用者のクレジットカード契約に準じます）。
              <br />
              その他、当社が定める方法。
            </dd>
            <dt>■解約又は解約の条件</dt>
            <dd>
              契約期間が終了するタイミングでのご解約となります。日割り計算での返金および購入したチケットの返金はできません。最短1ヶ月更新でのご契約が可能です。解約後は、翌月以降の請求は行われません。その他料金に関する情報はこちらをご確認ください。
            </dd>
            <dt>■推奨環境</dt>
            <dd>
              <a href="/" target="_blank">
                公式サイト
              </a>
              、別途資料、FAQをご覧ください。
            </dd>
            <dt>■サービス利用期間</dt>
            <dd>
              サイトからの利用登録後、ログインすることで直ちに無料でのサービスをご利用いただけます。無料でのサービスの終了後は、サブスクプランまたはチケットを選んで決済すると、直ちに有料サービスをご利用いただけます。
            </dd>
            <dt>■返品・返金について</dt>
            <dd>
              提供サービスの性質上、返品・交換は出来ません。中途解約による返金は承っておりません。
              <br />
              月額契約の更新停止を希望される場合は、ログイン後のメニューの「マイページ＞課金管理」画面で、解約の手続きをしてください。データの削除等のご要望については、ログイン後のお問い合わせフォームからお問い合わせください。
            </dd>
          </dl>
        </div>
      </div>
      <Footer />
    </>
  );
};
