import { NavLink } from "react-router-dom";

export const HeaderNotLogin = () => {
  return (
    <header className="not_login">
      <div className="logo_wrap">
        <NavLink to={"/"}>
          <img src="/images/logo_header.png" alt="AI占い by チャットGPT" />
        </NavLink>
      </div>
    </header>
  );
};
