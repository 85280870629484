import { auth, provider } from "../../firebase/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useState, useEffect, useRef } from "react";
import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";
import { toast } from "react-toastify";
import { getProfile } from "../../hooks/query";
import { getDocs } from "firebase/firestore";
import { isMobile } from "react-device-detect";
import { FaGoogle } from "react-icons/fa";
export const Login = () => {
  const navigation = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  const { state } = location;
  const toastId = useRef(null);
  useEffect(() => {
    if (toastId.current) {
      toast.update(toastId.current, { autoClose: 3000 });
    } else if (state) {
      toastId.current = toast.success(state.message, {
        autoClose: 3000,
      });
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      const data = await auth.signInWithEmailAndPassword(
        email.value,
        password.value
      );
      const profile = await getProfile(data.user.uid);
      getDocs(profile).then((querySnapshot) => {
        if (querySnapshot.size !== 0) {
          setIsSubmitting(false);
          navigation("/home");
        } else {
          setIsSubmitting(false);
          navigation("/profile");
        }
      });
    } catch (error) {
      setIsSubmitting(false);
      toast.error("メールアドレスまたはパスワードが違います。");
    }
  };

  const handleAuth = async (e) => {
    setIsSubmitting(true);
    try {
      const data = await auth.signInWithPopup(provider);
      const profile = await getProfile(data.user.uid);
      getDocs(profile).then((querySnapshot) => {
        if (querySnapshot.size !== 0) {
          setIsSubmitting(false);
          navigation("/home");
        } else {
          setIsSubmitting(false);
          navigation("/profile");
        }
      });
    } catch (error) {
      setIsSubmitting(false);
      toast.error("ログインできませんでした。");
    }
  };

  return (
    <>
      <HeadBlock title="ログイン" />
      <HeaderNotLogin />
      <div className="wrap not_login">
        {isSubmitting ? (
          <div className="loading">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form_wrap">
                <label>メールアドレス</label>
                <input name="email" type="email" />
              </div>
              <div className="form_wrap">
                <label>パスワード</label>
                <input name="password" type="password" />
              </div>
              <button type="submit" className="btn_important">
                ログイン
              </button>
            </form>
            <button onClick={handleAuth} className="btn_secondary mt20">
              <FaGoogle className="btn_iconfont" />
              Googleアカウントでログイン
            </button>
            <p className="center mt50">
              <Link to={"/signup"} className="btn_link big">
                新規登録（無料）
              </Link>
            </p>
            <p className="center mt30">
              パスワードを忘れた方は
              <Link to={"/password"} className="btn_link">
                こちら
              </Link>
            </p>
            {isMobile && (
              <img
                src="/images/img_appinfo.png"
                className="w100 mt50"
                alt="スマホのホーム画面に追加すると、アプリのように便利に使えます！"
              />
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};
