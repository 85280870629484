import { db } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { Footer } from "../components/Footer";
import { HeadBlock } from "../components/HeadBlock";
import firebase from "firebase/compat/app";
import { useState, useEffect } from "react";
import { getDay } from "../../utils/functions";
import { BirthdateSelect } from "../components/BirthdateSelect";
import { topMessage } from "../../utils/constans";
import { toast } from "react-toastify";
import TextOverlay from "../components/TextOverlay";
export const Top = () => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("男性");
  const [nickName, setNickname] = useState("");
  const [todayData, setTodayData] = useState([]);
  const [answer, setAnswer] = useState(null);
  const [buttonFlg, setButtonFlg] = useState(true);
  const { user } = useAuthContext();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_GOOGLE_API_ID}/values/Sheet1!A1:E?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );

        if (!response.ok) {
          throw new Error("データの取得に失敗しました");
        }

        const data = await response.json();
        const csvData = CsvDic(data.values);
        setData(csvData);

        const filterData = csvData.filter((item) => item.date === getDay());
        setTodayData(filterData[0]);
      } catch (error) {
        setTodayData("#隠れた才能を発見");
      }
    };

    fetchData();

    const fetchProfile = async () => {
      const Ref = db.collection("profiles").where("userId", "==", user.uid);
      const snap = await Ref.get();
      const doc = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNickname(doc[0].nickName);
      setBirthDate(doc[0].birthDate);
      setGender(doc[0].gender);
    };
    if (user) {
      fetchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CsvDic = (props) => {
    const [header, ...rows] = props;
    return rows.map((row) =>
      row.reduce((acc, cell, i) => ({ ...acc, [header[i]]: cell }), {})
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!nickName || !setBirthDate) {
      toast.error("ニックネーム、生年月日を入力してください");
      return;
    }

    setButtonFlg(false);
    setAnswer(null);

    const today = getDay();

    const text = `相談内容は以下の通りです。相談者の名前：${nickName}。性別：${gender}。生年月日：${birthDate}。今日の日付：${today}。相談内容：「${todayData.query}」。占いの結果 ホロスコープによると、今のあなたの運勢は絶好調です。`;

    const subscription = firebase.functions().httpsCallable("Fixchat");
    const response = await subscription({
      message: topMessage,
      text: text,
    });

    setAnswer(response.data.message.content);
  };

  return (
    <>
      <HeadBlock />
      <header className="lp">
        <h1>
          <img
            src="/images/lp_header_img.png"
            alt="どんな悩みも、30秒で解決 怖いほど当たる占い＆アドバイス 日本初の本格AI占い AI占い by チャットGPT"
          />
        </h1>
        <Link
          to={user ? "/home" : "/signup"}
          state={{
            nickName: nickName,
            gender: gender,
            birthDate: birthDate,
          }}
          className="btn_lp_login"
        >
          <img src="/images/lp_btn_login.png" alt="今すぐ無料占い1" />
        </Link>
        <p>
          ユーザーの方は
          <Link to={user ? "/home" : "/login"} className="btn_link">
            ログイン
          </Link>
        </p>
        <p className="mt30">Powered by 占術師SAIKI × ChatGPT</p>
      </header>
      <div className="wrap lp">
        <div className="lp_step_wrap">
          <div className="caption">３ステップでチャットAI占い</div>
          <ul>
            <li>
              <p>
                ステップ<span>1</span>
              </p>
              <span className="text">生年月日と性別を入力</span>
            </li>
            <li>
              <p>
                ステップ<span>2</span>
              </p>
              <span className="text">占いたいカテゴリを選択</span>
            </li>
            <li>
              <p>
                ステップ<span>3</span>
              </p>
              <span className="text">あなたのお悩みを入力</span>
            </li>
          </ul>
        </div>

        <div className="lp_movie_wrap">
          <video
            src="/images/mov_demo.mp4"
            poster="/images/img_mov_demo.png"
            muted
            autoPlay
            loop
            playsInline
          ></video>
        </div>

        <div className="lp_banner_wrap">
          <p className="lp_banner_txt">
            ユーザー登録して、あなたの悩みを相談！
            <br />
            本格AI占いが、今なら毎日1回鑑定無料！
          </p>
          <Link
            to={user ? "/home" : "/signup"}
            state={{
              nickName: nickName,
              gender: gender,
              birthDate: birthDate,
            }}
            className="btn_lp_login"
          >
            <img src="/images/lp_btn_login.png" alt="今すぐ無料占い2" />
          </Link>
          <p className="center mt30">
            ユーザーの方は
            <Link to={user ? "/home" : "/login"} className="btn_link">
              ログイン
            </Link>
          </p>
        </div>

        <div className="frame-frame101">
          <div className="frame-frame113">
            <span className="frame-text074">
              <span>
                <span>無料会員登録すると</span>
                <br />
                <span>お悩みを自由入力で</span>
                <br />
                <span>占えます！</span>
              </span>
            </span>
            <div className="frame-frame29">
              <span className="frame-text079">
                <span>＼当たりすぎてコワい…／</span>
              </span>
              <div className="frame-frame30">
                <div className="frame-frame31">
                  <div className="frame-frame13">
                    <div className="frame-group4">
                      <img
                        src="/images/campaign/vector4644-1te.svg"
                        alt="Vector4644"
                        className="frame-vector6"
                      />
                      <img
                        src="/images/campaign/vector4644-megl.svg"
                        alt="Vector4644"
                        className="frame-vector7"
                      />
                    </div>
                  </div>
                </div>
                <div className="frame-frame32">
                  <span className="frame-text081">
                    <span>
                      <span>
                        相談しにくい質問でも、なんでも答えてくれてもうこの占いなしでは生きていけません…
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="frame-frame321">
                <div className="frame-frame322">
                  <span className="frame-text086">
                    <span>
                      <span>
                        何度でも質問できるし、結果が保存できるから対面鑑定より使えるなって思います
                      </span>
                    </span>
                  </span>
                </div>
                <div className="frame-frame311">
                  <div className="frame-frame12">
                    <div className="frame-group4">
                      <img
                        src="/images/campaign/vector4644-wwwl.svg"
                        alt="Vector4644"
                        className="frame-vector6"
                      />
                      <img
                        src="/images/campaign/vector4644-7zk8.svg"
                        alt="Vector4644"
                        className="frame-vector7"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-frame312">
                <div className="frame-frame313">
                  <div className="frame-frame13">
                    <div className="frame-group4">
                      <img
                        src="/images/campaign/vector4644-1te.svg"
                        alt="Vector4644"
                        className="frame-vector6"
                      />
                      <img
                        src="/images/campaign/vector4644-megl.svg"
                        alt="Vector4644"
                        className="frame-vector7"
                      />
                    </div>
                  </div>
                </div>
                <div className="frame-frame323">
                  <span className="frame-text091">
                    <span>
                      <span>
                        本物の占い師が監修しているから、信ぴょう性があって良いです
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="frame-frame102">
              <div className="frame-frame103">
                <span className="frame-text079">
                  <span>次はあなたの番です</span>
                </span>
                <Link
                  to={"/signup"}
                  state={{
                    nickName: nickName,
                    gender: gender,
                    birthDate: birthDate,
                  }}
                  className="btn_lp_login"
                >
                  <img src="/images/lp_btn_login.png" alt="今すぐ無料占い3" />
                </Link>
              </div>
              <div className="frame-frame33">
                <div className="frame-frame34">
                  <span className="frame-text074">
                    <span>
                      <span>どんな悩みでも、</span>
                      <br />
                      <span>うち明けてくださいね</span>
                    </span>
                  </span>
                </div>
                <div className="frame-frame36">
                  <div className="frame-frame35">
                    <img
                      src="/images/campaign/dalle2023102314230411777-vmzt-300h.png"
                      alt="DALLE2023102314230411777"
                      className="frame-dalle202310231423041"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-frame122">
            <span className="frame-text074">
              <span>質問例</span>
            </span>
            <div className="frame-frame291">
              <div className="frame-frame301">
                <div className="frame-frame314">
                  <span className="frame-text105">
                    <span>恋愛</span>
                  </span>
                </div>
                <div className="frame-frame324">
                  <span className="frame-text107">
                    <span>
                      <span>
                        ・好きな人がいます。その人は、今私のことをどう思っていますか？
                      </span>
                      <br />
                      <span>
                        ・私の運命の相手の特徴を、３つ教えてください！
                      </span>
                      <br />
                      <span>
                        ・今、好きな人にはパートナーがいます。いつ別れてくれますか？
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="frame-frame301">
                <div className="frame-frame314">
                  <span className="frame-text114">
                    <span>仕事</span>
                  </span>
                </div>
                <div className="frame-frame325">
                  <span className="frame-text107">
                    <span>
                      <span>
                        ・今の職場が自分に合っていない気がします。転職すべき？
                      </span>
                      <br />
                      <span>・毎日ツラいです…。仕事でいつ評価されますか？</span>
                      <br />
                      <span>・私の仕事に活かせる才能は何ですか？</span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="frame-frame301">
                <div className="frame-frame314">
                  <span className="frame-text123">
                    <span>金運</span>
                  </span>
                </div>
                <div className="frame-frame326">
                  <span className="frame-text107">
                    <span>
                      <span>
                        ・収入をアップさせたいです。副業した方がいい？
                      </span>
                      <br />
                      <span>・臨時収入はいつ入ってきそうですか？</span>
                      <br />
                      <span>
                        ・金運をアップさせるためには何をしたらいいですか？
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="frame-frame301">
                <div className="frame-frame314">
                  <span className="frame-text132">
                    <span>その他</span>
                  </span>
                </div>
                <div className="frame-frame327">
                  <span className="frame-text107">
                    <span>
                      ・どうして嫌なことばかり続くのですか？報われる日は来ますか？
                    </span>
                    <br />
                    <span>
                      ・信じていた人に裏切られました…どうしたら立ち直れますか？
                    </span>
                    <br />
                    <span>
                      ・私が才能を発揮して、本当に輝ける場所はどこにありますか？
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <Link
              to={"/signup"}
              state={{
                nickName: nickName,
                gender: gender,
                birthDate: birthDate,
              }}
              className="btn_lp_login"
            >
              <img src="/images/lp_btn_login.png" alt="今すぐ無料占い4" />
            </Link>
          </div>
        </div>

        <p className="frame-text074 mt50">
          日替わりの
          <br className="sp" />
          おためし占いメニューを
          <br className="pc" />
          占ってみよう♪
        </p>
        <p className="frame-text079 mt10">
          ※無料会員登録すると、
          <br className="sp" />
          お悩み自由入力で占えます！
        </p>

        <div id="trial" className="lp_trial_wrap">
          <div className="lp_trial_inr">
            <div className="caption">今日のおためしAI占い</div>
            <div className="theme">
              <p>{todayData ? todayData.hashtag : ""}</p>
              <p className="sub">ー {todayData ? todayData.question : ""} ー</p>
            </div>
            {buttonFlg ? (
              <form>
                <div className="form_wrap">
                  <label>ニックネーム</label>
                  <input
                    type="text"
                    value={nickName}
                    onChange={(e) => setNickname(e.target.value)}
                    placeholder="全角20文字まで"
                    maxLength={20}
                  />
                </div>
                <div className="form_wrap">
                  <label>生年月日</label>
                  <BirthdateSelect value={birthDate} onChange={setBirthDate} />
                </div>
                <div className="form_wrap">
                  <label>性別</label>
                  <div className="select">
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="男性">男性</option>
                      <option value="女性">女性</option>
                      <option value="その他">その他</option>
                    </select>
                  </div>
                </div>
                <button onClick={handleSubmit} className="btn_gradient_02">
                  無料で占う
                </button>
                <p>{answer ? answer : ""}</p>
              </form>
            ) : (
              <>
                {answer ? (
                  <div className="answer_wrap">
                    <p>それでは、答えていきますね。</p>
                    <div className="answer">{answer}</div>
                    <div className="btn_wrap">
                      <TextOverlay
                        url="https://ai-uranai.jp"
                        text={answer}
                        hashtag={todayData ? todayData.hashtag : ""}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="movie_loading lp_trial_loading">
                    <div className="movie_inr">
                      <div className="loading">
                        <div className="loader"></div>
                      </div>
                      <p>おためし鑑定中…</p>
                    </div>
                    <p className="remark">
                      ※10〜20秒ほど、画面移動せずにお待ちください。
                      <br />
                      ※アクセス集中時には、遅くなることがあります。
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="lp_banner_wrap">
          <p className="lp_banner_txt">
            ユーザー登録して、あなたの悩みを相談！
            <br />
            本格AI占いが、今なら毎日1回鑑定無料！
          </p>
          <Link
            to={user ? "/home" : "/signup"}
            state={{
              nickName: nickName,
              gender: gender,
              birthDate: birthDate,
            }}
            className="btn_lp_login"
          >
            <img src="/images/lp_btn_login.png" alt="今すぐ無料占い5" />
          </Link>
          <p className="center mt30">
            ユーザーの方は
            <Link to={user ? "/home" : "/login"} className="btn_link">
              ログイン
            </Link>
          </p>
        </div>

        <div className="saiki_profile_wrap">
          <div className="caption">監修者：占術師SAIKI</div>
          <img src="/images/img_saiki.png" alt="監修者：占術師SAIKI" />
          <p>
            大正時代から代々続く九星気学風水家系。幼い頃から両親に人生の方向性を占術で決められてきた。同時に占術を学ぶよう強いられるが反抗。しかし運命に導かれるように占術師としての道を歩むようになり、人生が好転。東洋占星術から西洋占星術まで幅広く扱う。
            <br />
            鑑定数約3000人。「背中を押してもらえた」「めちゃくちゃ当たってる」「心のこもった鑑定に涙が出ました」という声が日々寄せられている。
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};
