import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SignUp } from "./views/pages/SignUp";
import { Home } from "./views/pages/Home";
import { Login } from "./views/pages/Login";
import { Profile } from "./views/pages/Profile";
import { ProfileEdit } from "./views/pages/ProfileEdit";
import { Checkout } from "./views/pages/Checkout";
import { Top } from "./views/pages/Top";
import { Mypage } from "./views/pages/Mypage";
import { Ticket } from "./views/pages/Ticket";
import { ConfirmPassword } from "./views/pages/ConfirmPassword";
import { PasswordReset } from "./views/pages/PasswordReset";

import { AuthProvider } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Specified } from "./views/pages/Specified";
import { Guidline } from "./views/pages/Guidline";
import { ScrollTop } from "./views/components/ScrollTop";
import AdminLogin from "./views/pages/AdminLogin";
import AdminHome from "./views/pages/AdminHome";
const App = () => {
  return (
    <AuthProvider>
      <div>
        <BrowserRouter>
          <ScrollTop />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/home" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/mypage" element={<Mypage />} />
            <Route path="/ticket" element={<Ticket />} />
            <Route path="/password" element={<PasswordReset />} />
            <Route path="/confirm" element={<ConfirmPassword />} />
            <Route path="/specified" element={<Specified />} />
            <Route path="/guidline" element={<Guidline />} />
            <Route path="/master" element={<AdminLogin />} />
            <Route path="/master/home" element={<AdminHome />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
};

export default App;
