import React from "react";
import { TwitterShareButton } from "react-share";
import { FaXTwitter } from "react-icons/fa6";

function TwitterButton({ url, text, hashtag }) {
   let shareInrText = text;
   let shareText = "";

   // おためし占い
  if (hashtag) {
    const shareInrLength = 85 - hashtag.length;
    shareInrText = text.slice(0, shareInrLength);
    shareText = "【 " + hashtag + " 】\n『AI占い by チャットGPT』で占いました！\n\n" + shareInrText + "…\n\n#AI占い #占い #今日のおためし占い\n";
  // 通常の占い
  } else {
    shareInrText = text.slice(0, 98);
    shareText = "『AI占い by チャットGPT』で占いました。\n\n" + shareInrText + "…\n\n#AI占い #占い\n";
  }

  return (
    <TwitterShareButton url={url} title={shareText}>
      <div className="btn_share_twitter">
        <FaXTwitter className="btn_iconfont" />
        結果をシェア
      </div>
    </TwitterShareButton>
  );
}

export default TwitterButton;
