import { useNavigate, NavLink } from "react-router-dom";
import { auth } from "../../firebase/firebase";

export const Header = () => {
  const navigation = useNavigate();
  const handleLogout = () => {
    auth.signOut();
    navigation("/login", {
      state: { message: "ログアウトしました。" },
    });
  };
  return (
    <header>
      <div className="logo_wrap">
        <NavLink to={"/"}><img src="/images/logo_header.png" alt="AI占い by チャットGPT"/></NavLink>
        <button onClick={handleLogout}>ログアウト</button>
      </div>
      <nav>
        <NavLink to={"/home"}>今すぐ占う</NavLink>
        <NavLink to={"/mypage"}>マイページ</NavLink>
        <NavLink to={"/ticket"}>追加チケット</NavLink>
      </nav>
    </header>
  );
};
