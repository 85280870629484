import { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase";
import { useNavigate, Navigate } from "react-router-dom";
import { Header } from "../components/Header";
import { HeadBlock } from "../components/HeadBlock";
import { Footer } from "../components/Footer";
import { borderDays, constellationList } from "../../utils/constans";
import { updateProfile } from "../../hooks/query";
import { toast } from "react-toastify";
import { BirthdateSelect } from "../components/BirthdateSelect";

export const ProfileEdit = () => {
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("男性");
  const [nickName, setNickname] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [data, setData] = useState(null);
  const { user } = useAuthContext();
  const navigation = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = db
        .collection("profiles")
        .where("userId", "==", user.uid);
      const snapshot = await collectionRef.get();
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(documents[0]);
      setBirthDate(documents[0].birthDate);
      setGender(documents[0].gender);
      setNickname(documents[0].nickName);
      setIntroduction(documents[0].introduction);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ProfileUpdate = async (e) => {
    e.preventDefault();
    if (nickName === "" || birthDate === "") {
      toast.error("情報を入力してください");
      return;
    }
    const date = new Date(`${birthDate}`);
    const constellation = [];
    if (!isNaN(date)) {
      const month = date.getMonth();
      const day = date.getDate();

      const borderDay = borderDays[month];
      const cIndex = day < borderDay ? month : month + 1;

      if (cIndex >= constellationList.length) {
        // 12月後半生まれの場合
        constellation.push(constellationList[0]);
      } else {
        constellation.push(constellationList[cIndex]);
      }
    }

    updateProfile({
      id: data.id,
      birthDate: birthDate,
      constellation: constellation["0"],
      gender: gender,
      nickName: nickName,
      userId: user.uid,
      introduction: introduction,
    });

    navigation("/mypage", {
      state: { message: "プロフィールが更新されました" },
    });
  };

  const Section = () => {
    if (data === null) {
      return (
        <div className="loading">
          <div className="loader"></div>
        </div>
      );
    } else {
      return (
        <form>
          <div className="form_wrap">
            <label>ニックネーム</label>
            <input
              type="text"
              onChange={(e) => setNickname(e.target.value)}
              placeholder="全角20文字まで"
              maxLength={20}
              value={nickName}
            />
          </div>
          <div className="form_wrap">
            <label>生年月日</label>
            <BirthdateSelect value={birthDate} onChange={setBirthDate} />
          </div>
          <div className="form_wrap">
            <label>性別</label>
            <div className="select">
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="男性">男性</option>
                <option value="女性">女性</option>
                <option value="その他">その他</option>
              </select>
            </div>
          </div>
          <div className="form_wrap">
            <label>ユーザー情報</label>

            <textarea
              value={introduction}
              onChange={(e) => setIntroduction(e.target.value)}
              maxLength={400}
              placeholder="AI占い師にあなたのことを教えてください。自分のプロフィール、今の恋愛や仕事などの状況、長く続いている悩みなど。最大400文字"
            />
          </div>
          <div className="btn_wrap">
            <button onClick={ProfileUpdate} className="btn_important">
              変更
            </button>
            <button
              onClick={() => navigation("/mypage")}
              className="btn_secondary"
            >
              キャンセル
            </button>
          </div>
        </form>
      );
    }
  };

  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <HeadBlock title="プロフィール情報の変更" />
        <Header />
        <div className="wrap">{Section()}</div>
        <Footer />
      </>
    );
  }
};
